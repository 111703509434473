import React from "react";
import { Placeholder, Segment } from "semantic-ui-react";

function BodyLoader() {
  return (
    <Segment style={{ height: "calc(100vh - 60px)",marginTop:"0" }}>
      <Placeholder style={{ height: "calc(100vh -   90px)" }} fluid>
        <Placeholder.Image square />
      </Placeholder>
    </Segment>
  );
}

export default BodyLoader;
