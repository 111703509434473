import { put, call, takeLatest } from "redux-saga/effects";

import { toast } from "react-toastify";

import {
  LOGIN,
  LOGIN_LOADING,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_ERROR,
  UPDATE_PROFILE_LOADING,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_LOADING,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_LOADING,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  VERIFY_RESET_TOKEN,
  VERIFY_RESET_TOKEN_LOADING,
  VERIFY_RESET_TOKEN_SUCCESS,
  VERIFY_RESET_TOKEN_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_LOADING,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  GET_ROLES,
  GET_ROLES_LOADING,
  GET_ROLES_SUCCESS,
  GET_ROLES_ERROR,
  SET_USERS,
  SET_USERS_SUCCESS,
  SET_NOTIFICATIONS,
  SET_NOTIFICATIONS_SUCCESS,
  UPDATE_NOTIFICATION,
  GET_CURRENT_USER,
  GET_CURRENT_USER_LOADING,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_ERROR,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_LOADING,
  LOGOUT_ERROR,
} from "../types";

import {
  loginApi,
  updateProfileApi,
  changePasswordApi,
  forgotPasswordApi,
  verifyResetTokenApi,
  resetPasswordApi,
  getRolesApi,
  updateNotificationApi,
  getCurrentUserApi,
  logoutApi,
} from "../apis/user";

import { getErrorMsg } from "../../lib/helperFunctions";
import { Constant } from "../../lib/Constants";

function* loginSaga(data) {
  yield put({ type: LOGIN_LOADING, payload: true });

  try {
    const { history, location } = data.payload;
    delete data.payload.history;
    delete data.payload.location;

    let res = yield call(loginApi, data.payload);

    if (res.status === 0) {
      // toast.success(res.message);
      localStorage.setItem("authToken", res.result.token);
      yield put({ type: LOGIN_SUCCESS, payload: res.result.user });
      // localStorage.setItem("user", JSON.stringify(res.result.user));
      // history.push("/");
      if (location.state && location.state.from) {
        history.replace(location.state.from);
      } else {
        history.replace("/");
      }
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: LOGIN_ERROR, payload: errorMsg });
      if (errorMsg !== Constant.ALREADY_LOGGED_IN) {
        toast.error(errorMsg);
      }
    }
  } catch (error) {
    yield put({ type: LOGIN_ERROR, payload: error.toString() });
    toast.error(error.toString());
  }
}

function* updateProifleSaga(data) {
  yield put({ type: UPDATE_PROFILE_LOADING, payload: true });

  try {
    let res = yield call(updateProfileApi, data.payload);

    if (res.status === 0) {
      toast.success(res.message);
      yield put({
        type: UPDATE_PROFILE_SUCCESS,
        payload: res.result.data,
      });
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: UPDATE_PROFILE_ERROR, payload: errorMsg });
      toast.error(errorMsg);
    }
  } catch (error) {
    yield put({
      type: UPDATE_PROFILE_ERROR,
      payload: error.toString(),
    });
    toast.error(error.toString());
  }
}

function* changePasswordSaga(data) {
  yield put({ type: CHANGE_PASSWORD_LOADING, payload: true });

  try {
    let res = yield call(changePasswordApi, data.payload);

    if (res.status === 0) {
      toast.success(res.message);
      yield put({
        type: CHANGE_PASSWORD_SUCCESS,
        payload: res.result,
      });
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: CHANGE_PASSWORD_ERROR, payload: errorMsg });
      toast.error(errorMsg);
    }
  } catch (error) {
    yield put({
      type: CHANGE_PASSWORD_ERROR,
      payload: error.toString(),
    });
    toast.error(error.toString());
  }
}

function* forgotPasswordSaga(data) {
  yield put({ type: FORGOT_PASSWORD_LOADING, payload: true });

  try {
    let res = yield call(forgotPasswordApi, data.payload);

    if (res.status === 0) {
      toast.success(res.message, { autoClose: 20000 });
      yield put({
        type: FORGOT_PASSWORD_SUCCESS,
        payload: res.result,
      });
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: FORGOT_PASSWORD_ERROR, payload: errorMsg });
      toast.error(errorMsg, { autoClose: 20000 });
    }
  } catch (error) {
    yield put({
      type: FORGOT_PASSWORD_ERROR,
      payload: error.toString(),
    });
    toast.error(error.toString(), { autoClose: 20000 });
  }
}

function* verifyResetTokenSaga(data) {
  yield put({
    type: VERIFY_RESET_TOKEN_LOADING,
    payload: true,
  });

  try {
    const { history } = data.payload;
    delete data.payload.history;

    let res = yield call(verifyResetTokenApi, data.payload);

    if (res.status === 0) {
      yield put({ type: VERIFY_RESET_TOKEN_SUCCESS, payload: res.result.data });
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: VERIFY_RESET_TOKEN_ERROR, payload: errorMsg });
      history.push("/login");
    }
  } catch (error) {
    yield put({ type: VERIFY_RESET_TOKEN_ERROR, payload: error.toString() });
  }
}

function* resetPasswordSaga(data) {
  yield put({ type: RESET_PASSWORD_LOADING, payload: true });

  try {
    const { history } = data.payload;
    delete data.payload.history;

    let res = yield call(resetPasswordApi, data.payload);

    if (res.status === 0) {
      toast.success(res.message);
      yield put({
        type: RESET_PASSWORD_SUCCESS,
        payload: res.result,
      });
      history.push("/login");
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: RESET_PASSWORD_ERROR, payload: errorMsg });
      toast.error(errorMsg);
    }
  } catch (error) {
    yield put({
      type: RESET_PASSWORD_ERROR,
      payload: error.toString(),
    });
    toast.error(error.toString());
  }
}

function* getRolesSaga() {
  yield put({ type: GET_ROLES_LOADING, payload: true });

  try {
    let res = yield call(getRolesApi);

    // console.log("Response: ", res);

    if (res.status === 0) {
      yield put({ type: GET_ROLES_SUCCESS, payload: res.result });
      // toast.success(res.message);
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: GET_ROLES_ERROR, payload: errorMsg });
      toast.error(errorMsg);
    }
  } catch (error) {
    yield put({ type: GET_ROLES_ERROR, payload: error.toString() });
    toast.error(error.toString());
  }
}

function* getCurrentUserSaga() {
  yield put({ type: GET_CURRENT_USER_LOADING, payload: true });

  try {
    let res = yield call(getCurrentUserApi);

    // console.log("Response: ", res);

    if (res.status === 0) {
      yield put({ type: GET_CURRENT_USER_SUCCESS, payload: res.result });
      // toast.success(res.message);
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: GET_CURRENT_USER_ERROR, payload: errorMsg });
      // toast.error(errorMsg);
    }
  } catch (error) {
    yield put({ type: GET_CURRENT_USER_ERROR, payload: error.toString() });
    toast.error(error.toString());
  }
}

function* updateNotificationSaga(data) {
  try {
    yield call(updateNotificationApi, data.payload);
  } catch (error) {
    // console.log(error.toString());
    // toast.error(error.toString());
  }
}

function* setUsers(data) {
  yield put({ type: SET_USERS_SUCCESS, payload: data.payload });
}

function* setNotifications(data) {
  yield put({ type: SET_NOTIFICATIONS_SUCCESS, payload: data.payload });
}

function* logout(data) {
  yield put({ type: LOGOUT_LOADING, payload: true });

  try {
    let res = yield call(logoutApi, data.payload);
    let { history } = data.payload;
    // console.log(res);
    if (res.status === 0) {
      yield put({ type: LOGOUT_SUCCESS, payload: res.result });
      // toast.success(res.message);
      localStorage.removeItem("authToken");
      history.push("/login");
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: LOGOUT_ERROR, payload: errorMsg });
      toast.error(errorMsg);
    }
  } catch (error) {
    yield put({ type: LOGOUT_ERROR, payload: error.toString() });
    toast.error(error.toString());
  }
}

function* watchUserSaga() {
  yield takeLatest(LOGIN, loginSaga);
  yield takeLatest(UPDATE_PROFILE, updateProifleSaga);
  yield takeLatest(CHANGE_PASSWORD, changePasswordSaga);
  yield takeLatest(FORGOT_PASSWORD, forgotPasswordSaga);
  yield takeLatest(VERIFY_RESET_TOKEN, verifyResetTokenSaga);
  yield takeLatest(RESET_PASSWORD, resetPasswordSaga);
  yield takeLatest(GET_ROLES, getRolesSaga);
  yield takeLatest(GET_CURRENT_USER, getCurrentUserSaga);
  yield takeLatest(SET_USERS, setUsers);
  yield takeLatest(SET_NOTIFICATIONS, setNotifications);
  yield takeLatest(UPDATE_NOTIFICATION, updateNotificationSaga);
  yield takeLatest(LOGOUT, logout);
}

export default watchUserSaga;
