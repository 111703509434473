import mainApi from "../../lib/mainApi";

export let addApi = (data) => {
  let url = "roles";
  let result = mainApi.POST(url, data);
  return result;
};

export let updateApi = (data) => {
  let url = "roles";
  let result = mainApi.PUT(url, data);
  return result;
};

export let updatePermissionsApi = (data) => {
  let url = "roles/updatePermissions";
  let result = mainApi.PUT(url, data);
  return result;
};

export let deleteApi = (data) => {
  let url = "roles/removeRole";
  let result = mainApi.PUT(url, data);
  return result;
};
