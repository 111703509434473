import { applyMiddleware, createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import {composeWithDevTools} from 'redux-devtools-extension';
// import { createLogger } from "redux-logger";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
// import reducers from "../reducers/index";
import reducers from "../redux/reducers/root";
// import { rootSaga } from "../saga/rootSaga";
import { rootSaga } from "../redux/saga/root";

const persistConfig = {
  key: "root",
  storage: storage,
  // whitelist: ["userReducer"], // which reducer want to store
  // whitelist: ["userReducer", "projectReducer", "taskReducer"], // which reducer want to store
  blacklist: ["socketReducer"],
};

const sagaMiddleware = createSagaMiddleware();

const persistedReducer = persistReducer(persistConfig, reducers);
// const loggerMiddleware = createLogger({});

function configureStore(initialState) {
  // const middleWare = applyMiddleware(loggerMiddleware, sagaMiddleware);
  const middleWare = composeWithDevTools(applyMiddleware(sagaMiddleware));
  let store = createStore(persistedReducer, initialState, middleWare);
  let persistor = persistStore(store);
  return { store, persistor };
}

const { store, persistor } = configureStore({});
sagaMiddleware.run(rootSaga);
// export const store;
export { store, persistor };
