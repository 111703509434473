import React from "react";
import { Segment, Placeholder } from "semantic-ui-react";

function FallbackLoader() {
  return (
    <Segment style={{ height: "100vh", marginTop: "0" }}>
      <Placeholder style={{ height: "calc(100vh -   30px)" }} fluid>
        <Placeholder.Image square />
      </Placeholder>
    </Segment>
  );
}

export default FallbackLoader;
