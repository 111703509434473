import {
  SET_TASK_SOCKET,
  SET_NOTIFICATION_SOCKET,
  SET_PROJECT_SOCKET,
  SET_USER_SOCKET,
  SET_SEARCH_SOCKET,
  SET_ROLE_SOCKET,
  SOCKET_LOADING,
} from "../types";

let initialState = {
  taskSocket: null,
  notificationSocket: null,
  projectSocket: null,
  userSocket: null,
  searchSocket: null,
  roleSocket: null,
  socketLoading: false,
};

export const socketReducer = (state = initialState, action) => {
  switch (action.type) {
    // Set Task Socket
    case SET_TASK_SOCKET:
      return {
        ...state,
        taskSocket: action.payload,
      };

    // Set Notification Socket
    case SET_NOTIFICATION_SOCKET:
      return {
        ...state,
        notificationSocket: action.payload,
      };

    // Set Project Socket
    case SET_PROJECT_SOCKET:
      return {
        ...state,
        projectSocket: action.payload,
      };

    // Set User Socket
    case SET_USER_SOCKET:
      return {
        ...state,
        userSocket: action.payload,
      };

    // Set Search Socket
    case SET_SEARCH_SOCKET:
      return {
        ...state,
        searchSocket: action.payload,
      };

    // Set Role Socket
    case SET_ROLE_SOCKET:
      return {
        ...state,
        roleSocket: action.payload,
      };

    case SOCKET_LOADING:
      return {
        ...state,
        socketLoading:
          state.taskSocket?.connected &&
          state.notificationSocket?.connected &&
          state.projectSocket?.connected &&
          state.userSocket?.connected &&
          state.roleSocket?.connected &&
          state.searchSocket?.connected,
      };

    default:
      return {
        ...state,
      };
  }
};
