import React from "react";
import { Placeholder, Grid } from "semantic-ui-react";

function NotesTabLoader() {
  let n = 10;
  return (
    <Grid>
      {[...Array(n)].map((e, i) => (
        <Grid.Row style={{marginBottom:"-1em"}} key={i}>
          <Grid.Column width={11} style={{ marginLeft: "1em" }}>
            <Placeholder>
              <Placeholder.Line length="full"></Placeholder.Line>
            </Placeholder>
          </Grid.Column>
          <Grid.Column width={2}>
            <Placeholder className="note-loader-image first-image">
              <Placeholder.Image></Placeholder.Image>
            </Placeholder>
          </Grid.Column>
          <Grid.Column width={2}>
            <Placeholder className="note-loader-image">
              <Placeholder.Image></Placeholder.Image>
            </Placeholder>
          </Grid.Column>
        </Grid.Row>
      ))}
    </Grid>
  );
}

export default NotesTabLoader;
