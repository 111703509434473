import React from "react";
import { Grid, Placeholder,Segment } from "semantic-ui-react";

function WorkLogLoader() {
  let n = 7;
  return (
    <div>
      <Segment>
        <Grid
          className="accordion-loader-grid"
        >
          {[...Array(n)].map((e, i) => (
            <Grid.Row key={i}>
              <Grid.Column width={3}>
                <Placeholder>
                  <Placeholder.Line length={"very long"}></Placeholder.Line>
                </Placeholder>
              </Grid.Column>
              <Grid.Column width={3}>
                <Placeholder>
                  <Placeholder.Line length={"full"}></Placeholder.Line>
                </Placeholder>
              </Grid.Column>
              <Grid.Column width={7}>
                <Placeholder>
                  <Placeholder.Line length={"full"}></Placeholder.Line>
                </Placeholder>
              </Grid.Column>
              <Grid.Column width={2}>
                <Placeholder>
                  <Placeholder.Line length={"full"}></Placeholder.Line>
                </Placeholder>
              </Grid.Column>
            </Grid.Row>
          ))}
        </Grid>
      </Segment>
    </div>
  );
}

export default WorkLogLoader;
