import { put, call, takeLatest } from "redux-saga/effects";

import { toast } from "react-toastify";

import {
  ADD_TASK,
  ADD_TASK_LOADING,
  ADD_TASK_ERROR,
  ADD_TASK_SUCCESS,
  UPDATE_TASK,
  UPDATE_TASK_SUCCESS,
  UPDATE_TASK_LOADING,
  UPDATE_TASK_ERROR,
  ADD_LOG_WORK,
  ADD_LOG_WORK_LOADING,
  ADD_LOG_WORK_SUCCESS,
  ADD_LOG_WORK_ERROR,
  REMOVE_WORK_LOG,
  REMOVE_WORK_LOG_LOADING,
  REMOVE_WORK_LOG_SUCCESS,
  REMOVE_WORK_LOG_ERROR,
  REMOVE_TASK,
  REMOVE_TASK_LOADING,
  REMOVE_TASK_ERROR,
  REMOVE_TASK_SUCCESS,
  ADD_COMMENT,
  ADD_COMMENT_LOADING,
  ADD_COMMENT_ERROR,
  ADD_COMMENT_SUCCESS,
  REMOVE_COMMENT,
  REMOVE_COMMENT_LOADING,
  REMOVE_COMMENT_ERROR,
  REMOVE_COMMENT_SUCCESS,
  ADD_TASK_FILE,
  ADD_TASK_FILE_LOADING,
  ADD_TASK_FILE_ERROR,
  ADD_TASK_FILE_SUCCESS,
  REMOVE_TASK_FILE,
  REMOVE_TASK_FILE_LOADING,
  REMOVE_TASK_FILE_ERROR,
  REMOVE_TASK_FILE_SUCCESS,
  SET_TASKS,
  SET_TASKS_SUCCESS,
  PREVIEW_TASK_FILE,
  PREVIEW_TASK_FILE_ERROR,
  PREVIEW_TASK_FILE_LOADING,
  PREVIEW_TASK_FILE_SUCCESS,
  DOWNLOAD_TASK_FILE,
  PREVIEW_COMMENT_FILE,
  PREVIEW_COMMENT_FILE_LOADING,
  PREVIEW_COMMENT_FILE_SUCCESS,
  PREVIEW_COMMENT_FILE_ERROR,
  DOWNLOAD_COMMENT_FILE,
  SET_ADD_TASK_SUCCESS,
  SET_FILTERED_OPTIONS_SUCCESS,
  SET_FILTERED_OPTIONS,
} from "../types";

import {
  addTaskApi,
  updateTaskApi,
  addLogWorkApi,
  removeWorkLogApi,
  removeTaskApi,
  addCommentApi,
  addTaskFileApi,
  removeTaskFileApi,
  removeCommentApi,
  previewTaskFileApi,
  downloadTaskFileApi,
  previewCommentFileApi,
  downloadCommentFileApi,
} from "../apis/task";

import { getErrorMsg } from "../../lib/helperFunctions";

function* addTaskSaga(data) {
  yield put({ type: ADD_TASK_LOADING, payload: true });

  try {
    let res = yield call(addTaskApi, data.payload);

    // console.log(res);

    if (res.status === 0) {
      toast.success(res.message);
      yield put({ type: ADD_TASK_SUCCESS, payload: res.result });
      yield put({ type: SET_ADD_TASK_SUCCESS, payload: false });
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: ADD_TASK_ERROR, payload: errorMsg });
      toast.error(errorMsg);
    }
  } catch (error) {
    yield put({ type: ADD_TASK_ERROR, payload: error.toString() });
    toast.error(error.toString());
  }
}

function* updateTaskSaga(data) {
  yield put({ type: UPDATE_TASK_LOADING, payload: true });

  try {
    let res = yield call(updateTaskApi, data.payload);

    // console.log(res);

    if (res.status === 0) {
      // toast.success(res.message);
      yield put({ type: UPDATE_TASK_SUCCESS, payload: res.result });
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: UPDATE_TASK_ERROR, payload: errorMsg });
      toast.error(errorMsg);
    }
  } catch (error) {
    yield put({ type: UPDATE_TASK_ERROR, payload: error.toString() });
    toast.error(error.toString());
  }
}

function* addLogWorkSaga(data) {
  yield put({ type: ADD_LOG_WORK_LOADING, payload: true });

  try {
    let res = yield call(addLogWorkApi, data.payload);

    // console.log(res);

    if (res.status === 0) {
      // toast.success(res.message);
      yield put({ type: ADD_LOG_WORK_SUCCESS, payload: res.result });
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: ADD_LOG_WORK_ERROR, payload: errorMsg });
      toast.error(errorMsg);
    }
  } catch (error) {
    yield put({ type: ADD_LOG_WORK_ERROR, payload: error.toString() });
    toast.error(error.toString());
  }
}

function* removeWorkLogSaga(data) {
  yield put({ type: REMOVE_WORK_LOG_LOADING, payload: true });

  try {
    let res = yield call(removeWorkLogApi, data.payload);

    // console.log(res);

    if (res.status === 0) {
      // toast.success(res.message);
      yield put({ type: REMOVE_WORK_LOG_SUCCESS, payload: res.result });
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: REMOVE_WORK_LOG_ERROR, payload: errorMsg });
      toast.error(errorMsg);
    }
  } catch (error) {
    yield put({ type: REMOVE_WORK_LOG_ERROR, payload: error.toString() });
    toast.error(error.toString());
  }
}

function* removeTaskSaga(data) {
  yield put({ type: REMOVE_TASK_LOADING, payload: true });

  try {
    let res = yield call(removeTaskApi, data.payload);

    // console.log(res);

    if (res.status === 0) {
      toast.success(res.message);
      yield put({ type: REMOVE_TASK_SUCCESS, payload: res.result });
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: REMOVE_TASK_ERROR, payload: errorMsg });
      toast.error(errorMsg);
    }
  } catch (error) {
    yield put({ type: REMOVE_TASK_ERROR, payload: error.toString() });
    toast.error(error.toString());
  }
}

function* addCommentSaga(data) {
  yield put({ type: ADD_COMMENT_LOADING, payload: true });

  try {
    let res = yield call(addCommentApi, data.payload);

    // console.log(res);

    if (res.status === 0) {
      yield put({ type: ADD_COMMENT_SUCCESS, payload: res.result });
      // toast.success(res.message);
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: ADD_COMMENT_ERROR, payload: errorMsg });
      toast.error(errorMsg);
    }
  } catch (error) {
    yield put({ type: ADD_COMMENT_ERROR, payload: error.toString() });
    toast.error(error.toString());
  }
}

function* removeCommentSaga(data) {
  yield put({ type: REMOVE_COMMENT_LOADING, payload: true });

  try {
    let res = yield call(removeCommentApi, data.payload);

    // console.log(res);

    if (res.status === 0) {
      yield put({ type: REMOVE_COMMENT_SUCCESS, payload: res.result });
      toast.success(res.message);
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: REMOVE_COMMENT_ERROR, payload: errorMsg });
      toast.error(errorMsg);
    }
  } catch (error) {
    yield put({ type: REMOVE_COMMENT_ERROR, payload: error.toString() });
    toast.error(error.toString());
  }
}

function* addTaskFileSaga(data) {
  yield put({ type: ADD_TASK_FILE_LOADING, payload: true });

  try {
    let res = yield call(addTaskFileApi, data.payload);

    // console.log(res);

    if (res.status === 0) {
      yield put({ type: ADD_TASK_FILE_SUCCESS, payload: res.result });
      // toast.success(res.message);
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: ADD_TASK_FILE_ERROR, payload: errorMsg });
      toast.error(errorMsg);
    }
  } catch (error) {
    yield put({ type: ADD_TASK_FILE_ERROR, payload: error.toString() });
    toast.error(error.toString());
  }
}

function* removeTaskFileSaga(data) {
  yield put({ type: REMOVE_TASK_FILE_LOADING, payload: true });

  try {
    let res = yield call(removeTaskFileApi, data.payload);

    // console.log(res);

    if (res.status === 0) {
      yield put({ type: REMOVE_TASK_FILE_SUCCESS, payload: res.result });
      // toast.success(res.message);
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: REMOVE_TASK_FILE_ERROR, payload: errorMsg });
      toast.error(errorMsg);
    }
  } catch (error) {
    yield put({ type: REMOVE_TASK_FILE_ERROR, payload: error.toString() });
    toast.error(error.toString());
  }
}

function* setTasksSaga(data) {
  yield put({ type: SET_TASKS_SUCCESS, payload: data.payload });
}

function* previewTaskFileSaga(data) {
  yield put({ type: PREVIEW_TASK_FILE_LOADING, payload: true });
  try {
    let res = yield call(previewTaskFileApi, data.payload);

    if (res) {
      yield put({ type: PREVIEW_TASK_FILE_SUCCESS, payload: res.result });
    } else {
      yield put({ type: PREVIEW_TASK_FILE_ERROR, payload: "" });
      toast.error("Something went wrong!");
    }
  } catch (error) {
    toast.error(error.toString());
  }
}

function* downloadTaskFileSaga(data) {
  try {
    yield call(downloadTaskFileApi, data.payload);
  } catch (error) {
    toast.error(error.toString());
  }
}

function* previewCommentFileSaga(data) {
  yield put({ type: PREVIEW_COMMENT_FILE_LOADING, payload: true });
  try {
    let res = yield call(previewCommentFileApi, data.payload);

    if (res) {
      yield put({ type: PREVIEW_COMMENT_FILE_SUCCESS, payload: res.result });
    } else {
      yield put({ type: PREVIEW_COMMENT_FILE_ERROR, payload: "" });
      toast.error("Something went wrong!");
    }
  } catch (error) {
    toast.error(error.toString());
  }
}

function* downloadCommentFileSaga(data) {
  try {
    yield call(downloadCommentFileApi, data.payload);
  } catch (error) {
    toast.error(error.toString());
  }
}

function* setFilteredOptionsSaga(data) {
  yield put({ type: SET_FILTERED_OPTIONS_SUCCESS, payload: data.payload });
}

function* watchTaskSaga() {
  yield takeLatest(ADD_TASK, addTaskSaga);
  yield takeLatest(UPDATE_TASK, updateTaskSaga);
  yield takeLatest(ADD_LOG_WORK, addLogWorkSaga);
  yield takeLatest(REMOVE_WORK_LOG, removeWorkLogSaga);
  yield takeLatest(REMOVE_TASK, removeTaskSaga);
  yield takeLatest(ADD_COMMENT, addCommentSaga);
  yield takeLatest(REMOVE_COMMENT, removeCommentSaga);
  yield takeLatest(ADD_TASK_FILE, addTaskFileSaga);
  yield takeLatest(REMOVE_TASK_FILE, removeTaskFileSaga);
  yield takeLatest(SET_TASKS, setTasksSaga);
  yield takeLatest(PREVIEW_TASK_FILE, previewTaskFileSaga);
  yield takeLatest(DOWNLOAD_TASK_FILE, downloadTaskFileSaga);
  yield takeLatest(PREVIEW_COMMENT_FILE, previewCommentFileSaga);
  yield takeLatest(DOWNLOAD_COMMENT_FILE, downloadCommentFileSaga);
  yield takeLatest(SET_FILTERED_OPTIONS, setFilteredOptionsSaga);
}

export default watchTaskSaga;
