import { put, call, takeLatest } from "redux-saga/effects";

import { toast } from "react-toastify";

import {
  ADD_ROLE,
  ADD_ROLE_LOADING,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_ERROR,
  UPDATE_ROLE,
  UPDATE_ROLE_LOADING,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_ERROR,
  UPDATE_ROLE_PERMISSIONS,
  UPDATE_ROLE_PERMISSIONS_LOADING,
  UPDATE_ROLE_PERMISSIONS_SUCCESS,
  UPDATE_ROLE_PERMISSIONS_ERROR,
  DELETE_ROLE,
  DELETE_ROLE_LOADING,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_ERROR,
  SET_ROLES,
  SET_ROLES_SUCCESS,
} from "../types";

import {
  addApi,
  updateApi,
  deleteApi,
  updatePermissionsApi,
} from "../apis/roles_permission";

import { getErrorMsg } from "../../lib/helperFunctions";

function* addRoleSaga(data) {
  yield put({ type: ADD_ROLE_LOADING, payload: true });

  try {
    let res = yield call(addApi, data.payload);

    // console.log(res);

    if (res.status === 0) {
      toast.success(res.message);
      yield put({ type: ADD_ROLE_SUCCESS, payload: res.result });
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: ADD_ROLE_ERROR, payload: errorMsg });
      toast.error(errorMsg);
    }
  } catch (error) {
    yield put({ type: ADD_ROLE_ERROR, payload: error.toString() });
    toast.error(error.toString());
  }
}

function* updateRoleSaga(data) {
  yield put({ type: UPDATE_ROLE_LOADING, payload: true });

  try {
    let res = yield call(updateApi, data.payload);

    // console.log(res);

    if (res.status === 0) {
      toast.success(res.message);
      yield put({ type: UPDATE_ROLE_SUCCESS, payload: res.result });
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: UPDATE_ROLE_ERROR, payload: errorMsg });
      toast.error(errorMsg);
    }
  } catch (error) {
    yield put({ type: UPDATE_ROLE_ERROR, payload: error.toString() });
    toast.error(error.toString());
  }
}

function* updatePermissionsSaga(data) {
  yield put({ type: UPDATE_ROLE_PERMISSIONS_LOADING, payload: true });

  try {
    let res = yield call(updatePermissionsApi, data.payload);

    // console.log(res);

    if (res.status === 0) {
      toast.success(res.message);
      yield put({ type: UPDATE_ROLE_PERMISSIONS_SUCCESS, payload: res.result });
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: UPDATE_ROLE_PERMISSIONS_ERROR, payload: errorMsg });
      toast.error(errorMsg);
    }
  } catch (error) {
    yield put({
      type: UPDATE_ROLE_PERMISSIONS_ERROR,
      payload: error.toString(),
    });
    toast.error(error.toString());
  }
}

function* deleteRoleSaga(data) {
  yield put({ type: DELETE_ROLE_LOADING, payload: true });

  try {
    let res = yield call(deleteApi, data.payload);

    // console.log(res);

    if (res.status === 0) {
      toast.success(res.message);
      yield put({ type: DELETE_ROLE_SUCCESS, payload: res.result });
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: DELETE_ROLE_ERROR, payload: errorMsg });
      toast.error(errorMsg);
    }
  } catch (error) {
    yield put({ type: DELETE_ROLE_ERROR, payload: error.toString() });
    toast.error(error.toString());
  }
}

function* setRolesSaga(data) {
  yield put({ type: SET_ROLES_SUCCESS, payload: data.payload });
}

function* watchRolesSaga() {
  yield takeLatest(ADD_ROLE, addRoleSaga);
  yield takeLatest(UPDATE_ROLE, updateRoleSaga);
  yield takeLatest(UPDATE_ROLE_PERMISSIONS, updatePermissionsSaga);
  yield takeLatest(DELETE_ROLE, deleteRoleSaga);
  yield takeLatest(SET_ROLES, setRolesSaga);
}

export default watchRolesSaga;
