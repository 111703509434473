import {
  ADD_TASK_LOADING,
  ADD_TASK_ERROR,
  ADD_TASK_SUCCESS,
  UPDATE_TASK_ERROR,
  UPDATE_TASK_LOADING,
  UPDATE_TASK_SUCCESS,
  ADD_LOG_WORK_LOADING,
  ADD_LOG_WORK_SUCCESS,
  ADD_LOG_WORK_ERROR,
  REMOVE_WORK_LOG_LOADING,
  REMOVE_WORK_LOG_SUCCESS,
  REMOVE_WORK_LOG_ERROR,
  REMOVE_TASK_LOADING,
  REMOVE_TASK_ERROR,
  REMOVE_TASK_SUCCESS,
  ADD_COMMENT_LOADING,
  ADD_COMMENT_SUCCESS,
  ADD_COMMENT_ERROR,
  ADD_TASK_FILE_LOADING,
  ADD_TASK_FILE_ERROR,
  ADD_TASK_FILE_SUCCESS,
  REMOVE_TASK_FILE_LOADING,
  REMOVE_TASK_FILE_SUCCESS,
  REMOVE_TASK_FILE_ERROR,
  REMOVE_COMMENT_LOADING,
  REMOVE_COMMENT_ERROR,
  REMOVE_COMMENT_SUCCESS,
  SET_TASKS_SUCCESS,
  SET_ADD_TASK_SUCCESS,
  SET_SELECTED_TASK,
  PREVIEW_TASK_FILE_SUCCESS,
  PREVIEW_TASK_FILE_ERROR,
  PREVIEW_TASK_FILE_LOADING,
  PREVIEW_COMMENT_FILE_LOADING,
  PREVIEW_COMMENT_FILE_SUCCESS,
  PREVIEW_COMMENT_FILE_ERROR,
  SHOW_PROJECT_REPORTS,
  TASKS_LOADING,
  SET_FILTERED_OPTIONS_SUCCESS,
  SET_COMMENT_NOTIFICATIONS,
  SET_COMMON_NOTIFICATIONS,
  SET_TEMP_TASKS,
  // SET_TASK_SOCKET,
} from "../types";

let initialState = {
  addCommentLoading: false,
  removeCommentLoading: false,
  addTaskLoading: false,
  updateTaskLoading: false,
  addLogWorkLoading: false,
  removeWorkLogLoading: false,
  removeTaskLoading: false,
  addTaskFilesLoading: false,
  removeTaskFileLoading: false,
  previewTaskFileLoading: false,
  previewCommentFileLoading: false,
  tasks: null,
  tasksLoading: false,
  // taskSocket: null,
  selectedTaskId: "",
  error: "",
  previewTaskFilePath: "",
  previewCommentFilePath: "",
  addTaskSuccess: false,
  updateTaskSuccess: false,
  addLogWorkSuccess: false,
  removeWorkLogSuccess: false,
  addCommentSuccess: false,
  previewTaskFileSuccess: false,
  previewCommentFileSuccess: false,
  removeCommentSuccess: false,
  addTaskFilesSuccess: false,
  showReport: false,
  filterOptions: {
    priority: [],
    progress: [],
    assignee: [],
    collaborators: [],
    dueDate: "",
  },
  commentNotifications: [],
  commonNotifications: [],
};

export const taskReducer = (state = initialState, action) => {
  switch (action.type) {
    // Add task Actions
    case ADD_TASK_LOADING:
      return {
        ...state,
        addTaskLoading: action.payload,
        error: "",
        addTaskSuccess: false,
      };
    case ADD_TASK_SUCCESS:
      return {
        ...state,
        addTaskLoading: false,
        error: "",
        addTaskSuccess: true,
        // selectedTaskId: action.payload._id,
        //   tasks: {},
      };
    case SET_ADD_TASK_SUCCESS:
      return {
        ...state,
        addTaskSuccess: action.payload,
      };
    case ADD_TASK_ERROR:
      return {
        ...state,
        addTaskLoading: false,
        error: action.payload,
        addTaskSuccess: false,
      };

    // Update task Actions
    case UPDATE_TASK_LOADING:
      return {
        ...state,
        updateTaskLoading: action.payload,
        error: "",
        updateTaskSuccess: false,
      };
    case UPDATE_TASK_SUCCESS:
      return {
        ...state,
        updateTaskLoading: false,
        error: "",
        updateTaskSuccess: true,
      };
    case UPDATE_TASK_ERROR:
      return {
        ...state,
        updateTaskLoading: false,
        error: action.payload,
        updateTaskSuccess: false,
      };

    // Add Log Work Actions
    case ADD_LOG_WORK_LOADING:
      return {
        ...state,
        addLogWorkLoading: action.payload,
        error: "",
        addLogWorkSuccess: false,
      };
    case ADD_LOG_WORK_SUCCESS:
      return {
        ...state,
        addLogWorkLoading: false,
        error: "",
        addLogWorkSuccess: true,
      };
    case ADD_LOG_WORK_ERROR:
      return {
        ...state,
        addLogWorkLoading: false,
        error: action.payload,
        addLogWorkSuccess: false,
      };

    // Remove Work Log Actions
    case REMOVE_WORK_LOG_LOADING:
      return {
        ...state,
        removeWorkLogLoading: action.payload,
        error: "",
        removeWorkLogSuccess: false,
      };
    case REMOVE_WORK_LOG_SUCCESS:
      return {
        ...state,
        removeWorkLogLoading: false,
        error: "",
        removeWorkLogSuccess: true,
      };
    case REMOVE_WORK_LOG_ERROR:
      return {
        ...state,
        removeWorkLogLoading: false,
        error: action.payload,
        removeWorkLogSuccess: false,
      };

    // Remove task Actions
    case REMOVE_TASK_LOADING:
      return {
        ...state,
        removeTaskLoading: action.payload,
        error: "",
      };
    case REMOVE_TASK_SUCCESS:
      return {
        ...state,
        removeTaskLoading: false,
        error: "",
      };
    case REMOVE_TASK_ERROR:
      return {
        ...state,
        removeTaskLoading: false,
        error: action.payload,
      };

    // Add Comment Actions
    case ADD_COMMENT_LOADING:
      return {
        ...state,
        addCommentLoading: action.payload,
        error: "",
        addCommentSuccess: false,
      };
    case ADD_COMMENT_SUCCESS:
      return {
        ...state,
        addCommentLoading: false,
        error: "",
        addCommentSuccess: true,
      };
    case ADD_COMMENT_ERROR:
      return {
        ...state,
        addCommentLoading: false,
        error: action.payload,
        addCommentSuccess: false,
      };

    // Remove Comment Actions
    case REMOVE_COMMENT_LOADING:
      return {
        ...state,
        removeCommentLoading: action.payload,
        error: "",
        removeCommentSuccess: false,
      };
    case REMOVE_COMMENT_SUCCESS:
      return {
        ...state,
        removeCommentLoading: false,
        error: "",
        removeCommentSuccess: true,
      };
    case REMOVE_COMMENT_ERROR:
      return {
        ...state,
        removeCommentLoading: false,
        error: action.payload,
        removeCommentSuccess: false,
      };

    // Add Task File Actions
    case ADD_TASK_FILE_LOADING:
      return {
        ...state,
        addTaskFilesLoading: action.payload,
        error: "",
        addTaskFilesSuccess: false,
      };
    case ADD_TASK_FILE_SUCCESS:
      return {
        ...state,
        addTaskFilesLoading: false,
        error: "",
        addTaskFilesSuccess: true,
        // newFiles: action.payload,
      };
    case ADD_TASK_FILE_ERROR:
      return {
        ...state,
        addTaskFilesLoading: false,
        error: action.payload,
        addTaskFilesSuccess: false,
      };

    // Remove Task File Actions
    case REMOVE_TASK_FILE_LOADING:
      return {
        ...state,
        removeTaskFileLoading: action.payload,
      };
    case REMOVE_TASK_FILE_SUCCESS:
      return {
        ...state,
        removeTaskFileLoading: false,
      };
    case REMOVE_TASK_FILE_ERROR:
      return {
        ...state,
        removeTaskFileLoading: false,
      };

    // Set Tasks Action
    case TASKS_LOADING:
      return {
        ...state,
        tasksLoading: action.payload,
      };
    case SET_TASKS_SUCCESS:
      return {
        ...state,
        tasks: action.payload,
        tasksLoading: false,
      };

    // Set Selected Task Action
    case SET_SELECTED_TASK:
      return {
        ...state,
        selectedTaskId: action.payload,
      };

    // Preview Task File Actions
    case PREVIEW_TASK_FILE_LOADING:
      return {
        ...state,
        previewTaskFileLoading: action.payload,
        previewTaskFilePath: "",
        previewTaskFileSuccess: false,
      };
    case PREVIEW_TASK_FILE_SUCCESS:
      return {
        ...state,
        previewTaskFileLoading: false,
        previewTaskFilePath: action.payload,
        previewTaskFileSuccess: true,
      };
    case PREVIEW_TASK_FILE_ERROR:
      return {
        ...state,
        previewTaskFileLoading: false,
        previewTaskFilePath: "",
        previewTaskFileSuccess: false,
      };

    // Preview Comment File Actions
    case PREVIEW_COMMENT_FILE_LOADING:
      return {
        ...state,
        previewCommentFileLoading: action.payload,
        previewCommentFilePath: "",
        previewCommentFileSuccess: false,
      };
    case PREVIEW_COMMENT_FILE_SUCCESS:
      return {
        ...state,
        previewCommentFileLoading: false,
        previewCommentFilePath: action.payload,
        previewCommentFileSuccess: true,
      };
    case PREVIEW_COMMENT_FILE_ERROR:
      return {
        ...state,
        previewCommentFileLoading: false,
        previewCommentFilePath: "",
        previewCommentFileSuccess: false,
      };

    case SHOW_PROJECT_REPORTS:
      return {
        ...state,
        showReport: action.payload,
      };

    case SET_FILTERED_OPTIONS_SUCCESS:
      return {
        ...state,
        filterOptions: action.payload,
      };
    case SET_COMMENT_NOTIFICATIONS:
      return {
        ...state,
        commentNotifications: action.payload,
      };
    case SET_COMMON_NOTIFICATIONS:
      return {
        ...state,
        commonNotifications: action.payload,
      };

    //set tasks temporory when added
    case SET_TEMP_TASKS:
      return {
        ...state,
        tasks: action.payload,
      };

    default:
      return state;
  }
};
