import React from "react";
import { Placeholder, Grid } from "semantic-ui-react";

function ImageLoader() {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={5}>
          <Placeholder className="loader-image">
            <Placeholder.Image></Placeholder.Image>
          </Placeholder>
        </Grid.Column>
        <Grid.Column width={6}>
          <Placeholder>
            
              <Placeholder.Line length="full" />
              <Placeholder.Line length="medium" />
            
          </Placeholder>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default ImageLoader;
