import React from "react";
import { Grid, Placeholder, Card } from "semantic-ui-react";

function AccordionLoader() {
  return (
    <div className="accordion-segment">
      <Card fluid>
        <Grid className="accordion-loader-grid">
          <Grid.Row>
            <Grid.Column width={14}>
              <Placeholder>
                <Placeholder.Line length={"full"}></Placeholder.Line>
              </Placeholder>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="accordion-loader-second-row">
            <Grid.Column width={6}>
              <Placeholder>
                <Placeholder.Line length={"very long"}></Placeholder.Line>
              </Placeholder>
            </Grid.Column>
            <Grid.Column width={7}>
              <Placeholder>
                <Placeholder.Line length={"very long"}></Placeholder.Line>
              </Placeholder>
            </Grid.Column>
            <Grid.Column width={1}>
              <Placeholder className="accordion-image">
                <Placeholder.Image></Placeholder.Image>
              </Placeholder>
            </Grid.Column>
            <Grid.Column width={1}>
              <Placeholder className="accordion-image">
                <Placeholder.Image></Placeholder.Image>
              </Placeholder>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
    </div>
  );
}

export default AccordionLoader;
