import mainApi from "../../lib/mainApi";

export let createApi = (data) => {
  let url = "organization/";
  let result = mainApi.POST(url, data);
  return result;
};

export let inviteUserApi = (data) => {
  let url = "user/invite/";
  let result = mainApi.POST(url, data);
  return result;
};

export let resendInviteApi = (data) => {
  let url = "user/resendInvite/";
  let result = mainApi.POST(url, data);
  return result;
};

export let removeUserApi = (data) => {
  let url = "user/removeUser";
  let result = mainApi.PUT(url, data);
  return result;
};

export let verifyOrganizationApi = (data) => {
  let url = "organization/organizationExist/";
  let result = mainApi.POST(url, data);
  return result;
};

export let verifyUserApi = (data) => {
  let url = "user/userExist";
  let result = mainApi.POST(url, data);
  return result;
};

export let updateInvitedUserApi = (data) => {
  let url = "user/updateInvitedUser";
  let result = mainApi.PUT(url, data);
  return result;
};
