import mainApi from "../../lib/mainApi";
import fileApi from "../../lib/fileApi";

export let addTaskApi = (data) => {
  let url = "task/";
  let result = mainApi.POST(url, data);
  return result;
};

export const updateTaskApi = (data) => {
  let url = "task/";
  let result = mainApi.PUT(url, data);
  return result;
};

export const addLogWorkApi = (data) => {
  let url = "task/addLogWork";
  let result = mainApi.PUT(url, data);
  return result;
};

export const removeWorkLogApi = (data) => {
  let url = "task/removeWorkLog";
  let result = mainApi.PUT(url, data);
  return result;
};

export const removeTaskApi = (data) => {
  let url = "task/";
  let result = mainApi.DELETE(url, data);
  return result;
};

export const addCommentApi = (data) => {
  let url = "task/addComment";
  let result = fileApi.PUT(url, data);
  return result;
};

export const removeCommentApi = (data) => {
  let url = "task/removeComment";
  let result = mainApi.PUT(url, data);
  return result;
};

// TODO: Missing action needs to be added!

export const addTaskFileApi = (data) => {
  let url = "file/";
  let result = fileApi.POST(url, data);
  return result;
};

export const removeTaskFileApi = (data) => {
  let url = "file/";
  let result = mainApi.PUT(url, data);
  return result;
};

export const previewTaskFileApi = (data) => {
  let url = `file/${data.id}`;
  let result = fileApi.FILE_PREVIEW(url, data.fileName);
  return result;
};

export const downloadTaskFileApi = (data) => {
  let url = `file/download/${data.id}`;
  let result = fileApi.GET(url, data.fileName);
  return result;
};

export const previewCommentFileApi = (data) => {
  let url = `file/${data.id}`;
  let result = fileApi.FILE_PREVIEW(url, data.fileName);
  return result;
};

export const downloadCommentFileApi = (data) => {
  let url = `file/download/${data.id}`;
  let result = fileApi.GET(url, data.fileName);
  return result;
};
