import React from "react";
import {Grid,Placeholder} from "semantic-ui-react";

function UsersTabLoader() {
  let n = 8;
  return (
    <div>
      <Grid className="accordion-loader-grid">
        {[...Array(n)].map((e, i) => (
          <Grid.Row key={i}>
          <Grid.Column width={2}>
            <Placeholder>
              <Placeholder.Line length={"long"}></Placeholder.Line>
            </Placeholder>
          </Grid.Column>
          <Grid.Column width={4}>
          </Grid.Column>
          <Grid.Column width={3}>
            <Placeholder>
              <Placeholder.Line length={"long"}></Placeholder.Line>
            </Placeholder>
          </Grid.Column>
          <Grid.Column width={4}>
            <Placeholder>
              <Placeholder.Line length={"very long"}></Placeholder.Line>
            </Placeholder>
          </Grid.Column>
          <Grid.Column width={2}>
            <Placeholder>
              <Placeholder.Line length={"long"}></Placeholder.Line>
            </Placeholder>
          </Grid.Column>
        </Grid.Row>
        ))}
      </Grid>
    </div>
  );
}

export default UsersTabLoader;
