import mainApi from "../../lib/mainApi";
import fileApi from "../../lib/fileApi";

// Project Api
export let addApi = (data) => {
  let url = "project/";
  let result = mainApi.POST(url, data);
  return result;
};

export let editApi = (data) => {
  let url = "project/";
  let result = mainApi.PUT(url, data);
  return result;
};

// Note Api
export const addNoteApi = (data) => {
  let url = "note/";
  let result = mainApi.POST(url, data);
  return result;
};

export const editNoteApi = (data) => {
  let url = "note/";
  let result = mainApi.PUT(url, data);
  return result;
};

export const deleteNoteApi = (data) => {
  let url = "note/remove/";
  let result = mainApi.PUT(url, data);
  return result;
};

// Project File Api
export const addFileApi = (data) => {
  let url = "file/";
  let result = fileApi.POST(url, data);
  return result;
};

export const removeFileApi = (data) => {
  let url = "file/";
  let result = mainApi.PUT(url, data);
  return result;
};

// User Api
export const inviteUserApi = (data) => {
  let url = "project/inviteUser/";
  let result = mainApi.POST(url, data);
  return result;
};

export const resendInviteApi = (data) => {
  let url = "project/resendInviteUser";
  let result = mainApi.POST(url, data);
  return result;
};

export const verifyInvitationApi = (data) => {
  let url = "/project/verifyInviteToken/";
  let result = mainApi.POST(url, data);
  return result;
};

export const acceptRejectInvitationApi = (data) => {
  let url = "/project/userAcceptOrReject";
  let result = mainApi.POST(url, data);
  return result;
};

export const updateUserRoleApi = (data) => {
  let url = "project/updateRole";
  let result = mainApi.POST(url, data);
  return result;
};

export const removeUserApi = (data) => {
  let url = "project/removeUser/";
  let result = mainApi.POST(url, data);
  return result;
};

export const previewFileApi = (data) => {
  let url = `file/${data.id}`;
  let result = fileApi.FILE_PREVIEW(url, data.fileName);
  return result;
};

export const downloadFileApi = (data) => {
  let url = `file/download/${data.id}`;
  let result = fileApi.GET(url, data.fileName);
  return result;
};
