import React from "react";
import { Placeholder } from "semantic-ui-react";

function TitleLoader() {
  return (
    <Placeholder fluid>
      <Placeholder.Line></Placeholder.Line>
    </Placeholder>
  );
}

export default TitleLoader;
