import {
  ADD_ROLE_LOADING,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_ERROR,
  UPDATE_ROLE_LOADING,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_ERROR,
  UPDATE_ROLE_PERMISSIONS_LOADING,
  UPDATE_ROLE_PERMISSIONS_SUCCESS,
  UPDATE_ROLE_PERMISSIONS_ERROR,
  DELETE_ROLE_LOADING,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_ERROR,
  SET_ROLES_SUCCESS,
  SET_SELECTED_ROLE,
} from "../types";

let initialState = {
  error: "",
  addRoleLoading: false,
  updateRoleLoading: false,
  updatePermissionsLoading: false,
  deleteRoleLoading: false,
  roles: [],
  selectedRoleId: "",
  addRoleSuccess: false,
  updateRoleSuccess: false,
  updatePermissionsSuccess: false,
  deleteRoleSuccess: false,
};

export const rolesReducer = (state = initialState, action) => {
  switch (action.type) {
    // Add Role Action
    case ADD_ROLE_LOADING:
      return {
        ...state,
        addRoleLoading: action.payload,
        error: "",
        addRoleSuccess: false,
      };
    case ADD_ROLE_SUCCESS:
      return {
        ...state,
        addRoleLoading: false,
        error: "",
        addRoleSuccess: true,
      };
    case ADD_ROLE_ERROR:
      return {
        ...state,
        addRoleLoading: false,
        error: action.payload,
        addRoleSuccess: false,
      };

    // Update Role Action
    case UPDATE_ROLE_LOADING:
      return {
        ...state,
        updateRoleLoading: action.payload,
        error: "",
        updateRoleSuccess: false,
      };
    case UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        updateRoleLoading: false,
        error: "",
        updateRoleSuccess: true,
      };
    case UPDATE_ROLE_ERROR:
      return {
        ...state,
        updateRoleLoading: false,
        error: action.payload,
        updateRoleSuccess: false,
      };

    // Update Permissions Action
    case UPDATE_ROLE_PERMISSIONS_LOADING:
      return {
        ...state,
        updatePermissionsLoading: action.payload,
        error: "",
        updatePermissionsSuccess: false,
      };
    case UPDATE_ROLE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        updatePermissionsLoading: false,
        error: "",
        updatePermissionsSuccess: true,
      };
    case UPDATE_ROLE_PERMISSIONS_ERROR:
      return {
        ...state,
        updatePermissionsLoading: false,
        error: action.payload,
        updatePermissionsSuccess: false,
      };

    // Delete Role Action
    case DELETE_ROLE_LOADING:
      return {
        ...state,
        deleteRoleLoading: action.payload,
        error: "",
        deleteRoleSuccess: false,
      };
    case DELETE_ROLE_SUCCESS:
      return {
        ...state,
        deleteRoleLoading: false,
        error: "",
        deleteRoleSuccess: true,
      };
    case DELETE_ROLE_ERROR:
      return {
        ...state,
        deleteRoleLoading: false,
        error: action.payload,
        deleteRoleSuccess: false,
      };

    // Set Role Action
    case SET_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload,
      };

    // Set Selected Role Action
    case SET_SELECTED_ROLE:
      return {
        ...state,
        selectedRoleId: action.payload,
      };

    default:
      return state;
  }
};
