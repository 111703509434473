import { combineReducers } from "redux";
import { LOGOUT } from "../types";

import * as organizationReducer from "./organization";
import * as userReducer from "./user";
import * as projectReducer from "./project";
import * as taskReducer from "./task";
import * as socketReducer from "./socket";
import * as rolesReducer from "./roles_permission";

const appReducer = combineReducers(
  Object.assign(
    {},
    organizationReducer,
    userReducer,
    projectReducer,
    taskReducer,
    socketReducer,
    rolesReducer
  )
);

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
