import mainApi from "../../lib/mainApi";
import fileApi from "../../lib/fileApi";

export let loginApi = (data) => {
  let url = "user/login";
  let result = mainApi.POST(url, data);
  return result;
};

export let updateProfileApi = (data) => {
  let url = "user/";
  let result = fileApi.PUT(url, data);
  return result;
};

export let changePasswordApi = (data) => {
  let url = "user/changePassword";
  let result = mainApi.POST(url, data);
  return result;
};

export let forgotPasswordApi = (data) => {
  let url = "user/forgotPassword";
  let result = mainApi.POST(url, data);
  return result;
};

export let resetPasswordApi = (data) => {
  let url = "user/resetPassword";
  let result = mainApi.POST(url, data);
  return result;
};

export let verifyResetTokenApi = (data) => {
  let url = "user/verifyCode";
  let result = mainApi.POST(url, data);
  return result;
};

export let getRolesApi = () => {
  let url = "user/roles";
  let result = mainApi.GET(url);
  return result;
};

export let updateNotificationApi = (data) => {
  let url = "notifications";
  let result = mainApi.PUT(url, data);
  return result;
};

export let getCurrentUserApi = () => {
  let url = "user/getLoggedInUser";
  let result = mainApi.GET(url);
  return result;
};

export let logoutApi = (data) => {
  let url = "user/logout";
  let result = mainApi.POST(url,data);
  return result;
};
