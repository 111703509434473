import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./css/toastify.css";
import "react-quill/dist/quill.snow.css";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import { FallbackLoader } from "./components/Loader";

const loading = <FallbackLoader />;

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

//views
const CreateAccount = React.lazy(() => import("./views/CreateAccount"));
const Login = React.lazy(() => import("./views/Login"));
const ForgotPassword = React.lazy(() => import("./views/ForgotPassword"));
const ResetPassword = React.lazy(() => import("./views/ResetPassword"));

function App() {
  return (
    <>
      <Provider store={store}>
        <PersistGate
          loading={<FallbackLoader />}
          persistor={persistor}
        >
          <BrowserRouter>
            <ToastContainer />
            <React.Suspense fallback={loading}>
              <Switch>
                <Route
                  path="/create-account"
                  name="Create Account"
                  render={(props) => <CreateAccount {...props} />}
                />

                <Route
                  path="/user/invite/:orgId/:userId"
                  name="Invite User"
                  render={(props) => <CreateAccount {...props} />}
                />
                <Route
                  path="/login"
                  name="Log In"
                  render={(props) => <Login {...props} />}
                />

                <Route
                  path="/forgot-password"
                  name="Forgot Password"
                  render={(props) => <ForgotPassword {...props} />}
                />

                <Route
                  path="/reset/:resetToken"
                  name="Reset Password"
                  render={(props) => <ResetPassword {...props} />}
                />

                <Route
                  path="/"
                  name="Home"
                  render={(props) => <TheLayout {...props} />}
                />
              </Switch>
            </React.Suspense>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
