// Organization Actions
export const CREATE_ORG_ACCOUNT = "CREATE_ORG_ACCOUNT";
export const CREATE_ORG_ACCOUNT_LOADING = "CREATE_ORG_ACCOUNT_LOADING";
export const CREATE_ORG_ACCOUNT_ERROR = "CREATE_ORG_ACCOUNT_ERROR";

// User Login Actions
export const LOGIN = "LOGIN";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

// User Logout Actions
export const LOGOUT = "LOGOUT";
export const LOGOUT_LOADING = "LOGOUT_LOADING";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";

// Get Current User
export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const GET_CURRENT_USER_LOADING = "GET_CURRENT_USER_LOADING";
export const GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS";
export const GET_CURRENT_USER_ERROR = "GET_CURRENT_USER_ERROR";

// Forgot Password Actions
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_LOADING = "FORGOT_PASSWORD_LOADING";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

// Change Password Acions
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_LOADING = "CHANGE_PASSWORD_LOADING";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

// Reset Password Acions
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_LOADING = "RESET_PASSWORD_LOADING";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

// Verify Reset Token Actions
export const VERIFY_RESET_TOKEN = "VERIFY_RESET_TOKEN";
export const VERIFY_RESET_TOKEN_LOADING = "VERIFY_RESET_TOKEN_LOADING";
export const VERIFY_RESET_TOKEN_SUCCESS = "VERIFY_RESET_TOKEN_SUCCESS";
export const VERIFY_RESET_TOKEN_ERROR = "VERIFY_RESET_TOKEN_ERROR";

// Add Project Actions
export const ADD_PROJECT = "ADD_PROJECT";
export const ADD_PROJECT_LOADING = "ADD_PROJECT-LOADING";
export const ADD_PROJECT_ERROR = "ADD_PROJECT_ERROR";
export const ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS";

// Update Project Actions
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const UPDATE_PROJECT_LOADING = "UPDATE_PROJECT_LOADING";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const UPDATE_PROJECT_ERROR = "UPDATE_PROJECT_ERROR";

// Edit Project Actions
export const EDIT_PROJECT = "EDIT_PROJECT";
export const EDIT_PROJECT_LOADING = "EDIT_PROJECT_LOADING";
export const EDIT_PROJECT_ERROR = "EDIT_PROJECT_ERROR";
export const EDIT_PROJECT_SUCCESS = "EDIT_PROJECT_SUCCESS";

// Invite Organization User Actions
export const INVITE_USER = "INVITE_USER";
export const INVITE_USER_LOADING = "INVITE_USER_LOADING";
export const INVITE_USER_ERROR = "INVITE_USER_ERROR";
export const INVITE_USER_SUCCESS = "INVITE_USER_SUCCESS";

// Resend Invite Organizaton User Actions
export const RESEND_INVITE = "RESEND_INVITE";
export const RESEND_INVITE_LOADING = "RESEND_INVITE_LOADING";
export const RESEND_INVITE_SUCCESS = "RESEND_INVITE_SUCCESS";
export const RESEND_INVITE_ERROR = "RESEND_INVITE_ERROR";

// Remove Organization User Actions
export const REMOVE_USER = "REMOVE_USER";
export const REMOVE_USER_LOADING = "REMOVE_USER_LOADING";
export const REMOVE_USER_ERROR = "REMOVE_USER_ERROR";
export const REMOVE_USER_SUCCESS = "REMOVE_USER_SUCCESS";

// Update User Profile
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_LOADING = "UPDATE_PROFILE_LOADING";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_ERROR = "UPDATE_PROFILE_ERROR";

// Get User Roles
export const GET_ROLES = "GET_ROLES";
export const GET_ROLES_LOADING = "GET_ROLES_LOADING";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_ERROR = "GET_ROLES_ERROR";

// Add Task Comments
export const ADD_COMMENT = "ADD_COMMENT";
export const ADD_COMMENT_LOADING = "ADD_COMMENT_LOADING";
export const ADD_COMMENT_ERROR = "ADD_COMMENT_ERROR";
export const ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS";

// Remove Task Comments
export const REMOVE_COMMENT = "REMOVE_COMMENT";
export const REMOVE_COMMENT_LOADING = "REMOVE_COMMENT_LOADING";
export const REMOVE_COMMENT_ERROR = "REMOVE_COMMENT_ERROR";
export const REMOVE_COMMENT_SUCCESS = "REMOVE_COMMENT_SUCCESS";

// Add Project Note
export const ADD_NOTE = "ADD_NOTE";
export const ADD_NOTE_LOADING = "ADD_NOTE_LOADING";
export const ADD_NOTE_ERROR = "ADD_NOTE_ERROR";
export const ADD_NOTE_SUCCESS = "ADD_NOTE_SUCCESS";

// Edit Project Note
export const EDIT_NOTE = "EDIT_NOTE";
export const EDIT_NOTE_LOADING = "EDIT_NOTE_LOADING";
export const EDIT_NOTE_ERROR = "EDIT_NOTE_ERROR";
export const EDIT_NOTE_SUCCESS = "EDIT_NOTE_SUCCESS";

// Delete Project Note
export const DELETE_NOTE = "DELETE_NOTE";
export const DELETE_NOTE_LOADING = "DELETE_NOTE_LOADING";
export const DELETE_NOTE_ERROR = "DELETE_NOTE_ERROR";
export const DELETE_NOTE_SUCCESS = "DELETE_NOTE_SUCCESS";

// Add Project File
export const ADD_FILE = "ADD_FILE";
export const ADD_FILE_LOADING = "ADD_FILE_LOADING";
export const ADD_FILE_ERROR = "ADD_FILE_ERROR";
export const ADD_FILE_SUCCESS = "ADD_FILE_SUCCESS";

// Remove Project File
export const REMOVE_FILE = "REMOVE_FILE";
export const REMOVE_FILE_LOADING = "REMOVE_FILE_LOADING";
export const REMOVE_FILE_ERROR = "REMOVE_FILE_ERROR";
export const REMOVE_FILE_SUCCESS = "REMOVE_FILE_SUCCESS";

// Invite Project User
export const INVITE_PROJECT_USER = "INVITE_PROJECT_USER";
export const INVITE_PROJECT_USER_LOADING = "INVITE_PROJECT_USER_LOADING";
export const INVITE_PROJECT_USER_ERROR = "INVITE_PROJECT_USER_ERROR";
export const INVITE_PROJECT_USER_SUCCESS = "INVITE_PROJECT_USER_SUCCESS";

// Update Project User Role
export const UPDATE_PROJECT_USER_ROLE = "UPDATE_PROJECT_USER_ROLE";
export const UPDATE_PROJECT_USER_ROLE_LOADING =
  "UPDATE_PROJECT_USER_ROLE_LOADING";
export const UPDATE_PROJECT_USER_ROLE_ERROR = "UPDATE_PROJECT_USER_ROLE_ERROR";
export const UPDATE_PROJECT_USER_ROLE_SUCCESS =
  "UPDATE_PROJECT_USER_ROLE_SUCCESS";

// Resend Project User
export const RESEND_PROJECT_INVITE = "RESEND_PROJECT_INVITE";
export const RESEND_PROJECT_INVITE_LOADING = "RESEND_PROJECT_INVITE_LOADING";
export const RESEND_PROJECT_INVITE_ERROR = "RESEND_PROJECT_INVITE_ERROR";
export const RESEND_PROJECT_INVITE_SUCCESS = "RESEND_PROJECT_INVITE_SUCCESS";

// Remove Project User
export const REMOVE_PROJECT_USER = "REMOVE_PROJECT_USER";
export const REMOVE_PROJECT_USER_LOADING = "REMOVE_PROJECT_USER_LOADING";
export const REMOVE_PROJECT_USER_ERROR = "REMOVE_PROJECT_USER_ERROR";
export const REMOVE_PROJECT_USER_SUCCESS = "REMOVE_PROJECT_USER_SUCCESS";

// Add Task
export const ADD_TASK = "ADD_TASK";
export const ADD_TASK_LOADING = "ADD_TASK_LOADING";
export const ADD_TASK_ERROR = "ADD_TASK_ERROR";
export const ADD_TASK_SUCCESS = "ADD_TASK_SUCCESS";

// Update Task
export const UPDATE_TASK = "UPDATE_TASK";
export const UPDATE_TASK_LOADING = "UPDATE_TASK_LOADING";
export const UPDATE_TASK_SUCCESS = "UPDATE_TASK_SUCCESS";
export const UPDATE_TASK_ERROR = "UPDATE_TASK_ERROR";

// Add Log Work
export const ADD_LOG_WORK = "ADD_LOG_WORK";
export const ADD_LOG_WORK_LOADING = "ADD_LOG_WORK_LOADING";
export const ADD_LOG_WORK_SUCCESS = "ADD_LOG_WORK_SUCCESS";
export const ADD_LOG_WORK_ERROR = "ADD_LOG_WORK_ERROR";

// Remove Work Log
export const REMOVE_WORK_LOG = "REMOVE_WORK_LOG";
export const REMOVE_WORK_LOG_LOADING = "REMOVE_WORK_LOG_LOADING";
export const REMOVE_WORK_LOG_SUCCESS = "REMOVE_WORK_LOG_SUCCESS";
export const REMOVE_WORK_LOG_ERROR = "REMOVE_WORK_LOG_ERROR";

// Remove Task
export const REMOVE_TASK = "REMOVE_TASK";
export const REMOVE_TASK_LOADING = "REMOVE_TASK_LOADING";
export const REMOVE_TASK_SUCCESS = "REMOVE_TASK_SUCCESS";
export const REMOVE_TASK_ERROR = "REMOVE_TASK_ERROR";

// Add Task File
export const ADD_TASK_FILE = "ADD_TASK_FILE";
export const ADD_TASK_FILE_LOADING = "ADD_TASK_FILE_LOADING";
export const ADD_TASK_FILE_ERROR = "ADD_TASK_FILE_ERROR";
export const ADD_TASK_FILE_SUCCESS = "ADD_TASK_FILE_SUCCESS";

// Add Remove File
export const REMOVE_TASK_FILE = "REMOVE_TASK_FILE";
export const REMOVE_TASK_FILE_LOADING = "REMOVE_TASK_FILE_LOADING";
export const REMOVE_TASK_FILE_ERROR = "REMOVE_TASK_FILE_ERROR";
export const REMOVE_TASK_FILE_SUCCESS = "REMOVE_TASK_FILE_SUCCESS";

// Project User Invite Verification
export const VERIFY_INVITATION = "VERIFY_INVITATION";
export const VERIFY_INVITATION_LOADING = "VERIFY_INVITATION_LOADING";
export const VERIFY_INVITATION_SUCCESS = "VERIFY_INVITATION_SUCCESS";
export const VERIFY_INVITATION_ERROR = "VERIFY_INVITATION_ERROR";

// Project User Invite Accept/Reject
export const CANCEL_PROJECT_INVITATION = "CANCEL_PROJECT_INVITATION";
export const CANCEL_PROJECT_INVITATION_ACTION =
  "CANCEL_PROJECT_INVITATION_ACTION";
export const ACCEPT_REJECT_PROJECT_INVITATION =
  "ACCEPT_REJECT_PROJECT_INVITATION";
export const ACCEPT_REJECT_PROJECT_INVITATION_LOADING =
  "ACCEPT_REJECT_PROJECT_INVITATION_LOADING";
export const ACCEPT_REJECT_PROJECT_INVITATION_SUCCESS =
  "ACCEPT_REJECT_PROJECT_INVITATION_SUCCESS";
export const ACCEPT_REJECT_PROJECT_INVITATION_ERROR =
  "ACCEPT_REJECT_PROJECT_INVITATION_ERROR";

// Organization User Invite Verification
export const VERIFY_USER_INVITATION = "VERIFY_USER_INVITATION";
export const VERIFY_USER_INVITATION_LOADING = "VERIFY_USER_INVITATION_LOADING";
export const VERIFY_USER_INVITAION_SUCCESS = "VERIFY_USER_INVITAION_SUCCESS";
export const VERIFY_USER_INVITAION_ERROR = "VERIFY_USER_INVITAION_ERROR";

// Update Invited User Action
export const UPDATE_INVITED_USER = "UPDATE_INVITED_USER";
export const UPDATE_INVITED_USER_LOADING = "UPDATE_INVITED_USER_LOADING";
export const UPDATE_INVITED_USER_SUCCESS = "UPDATE_INVITED_USER_SUCCESS";
export const UPDATE_INVITED_USER_ERROR = "UPDATE_INVITED_USER_ERROR";

// Preview Project File Action
export const PREVIEW_PROJECT_FILE = "PREVIEW_PROJECT_FILE";
export const PREVIEW_PROJECT_FILE_LOADING = "PREVIEW_PROJECT_FILE_LOADING";
export const PREVIEW_PROJECT_FILE_SUCCESS = "PREVIEW_PROJECT_FILE_SUCCESS";
export const PREVIEW_PROJECT_FILE_ERROR = "PREVIEW_PROJECT_FILE_ERROR";

// Download Project File Action
export const DOWNLOAD_PROJECT_FILE = "DOWNLOAD_PROJECT_FILE";
export const DOWNLOAD_PROJECT_FILE_LOADING = "DOWNLOAD_PROJECT_FILE_LOADING";
export const DOWNLOAD_PROJECT_FILE_SUCCESS = "DOWNLOAD_PROJECT_FILE_SUCCESS";
export const DOWNLOAD_PROJECT_FILE_ERROR = "DOWNLOAD_PROJECT_FILE_ERROR";

// Preview Task File Action
export const PREVIEW_TASK_FILE = "PREVIEW_TASK_FILE";
export const PREVIEW_TASK_FILE_LOADING = "PREVIEW_TASK_FILE_LOADING";
export const PREVIEW_TASK_FILE_SUCCESS = "PREVIEW_TASK_FILE_SUCCESS";
export const PREVIEW_TASK_FILE_ERROR = "PREVIEW_TASK_FILE_ERROR";

// Download Task File Action
export const DOWNLOAD_TASK_FILE = "DOWNLOAD_TASK_FILE";
export const DOWNLOAD_TASK_FILE_LOADING = "DOWNLOAD_TASK_FILE_LOADING";
export const DOWNLOAD_TASK_FILE_SUCCESS = "DOWNLOAD_TASK_FILE_SUCCESS";
export const DOWNLOAD_TASK_FILE_ERROR = "DOWNLOAD_TASK_FILE_ERROR";

//Show Project Repots
export const SHOW_PROJECT_REPORTS = "SHOW_PROJECT_REPORTS";

// Preview Comment File Action
export const PREVIEW_COMMENT_FILE = "PREVIEW_COMMENT_FILE";
export const PREVIEW_COMMENT_FILE_LOADING = "PREVIEW_COMMENT_FILE_LOADING";
export const PREVIEW_COMMENT_FILE_SUCCESS = "PREVIEW_COMMENT_FILE_SUCCESS";
export const PREVIEW_COMMENT_FILE_ERROR = "PREVIEW_COMMENT_FILE_ERROR";

// Download Comment File Action
export const DOWNLOAD_COMMENT_FILE = "DOWNLOAD_COMMENT_FILE";
export const DOWNLOAD_COMMENT_FILE_LOADING = "DOWNLOAD_COMMENT_FILE_LOADING";
export const DOWNLOAD_COMMENT_FILE_SUCCESS = "DOWNLOAD_COMMENT_FILE_SUCCESS";
export const DOWNLOAD_COMMENT_FILE_ERROR = "DOWNLOAD_COMMENT_FILE_ERROR";

// Search Action
export const SEARCH = "SEARCH";
export const SEARCH_LOADING = "SEARCH_LOADING";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const SEARCH_ERROR = "SEARCH_ERROR";

// Add Role Action
export const ADD_ROLE = "ADD_ROLE";
export const ADD_ROLE_LOADING = "ADD_ROLE_LOADING";
export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS";
export const ADD_ROLE_ERROR = "ADD_ROLE_ERROR";

export const UPDATE_ROLE = "UPDATE_ROLE";
export const UPDATE_ROLE_LOADING = "UPDATE_ROLE_LOADING";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_ERROR = "UPDATE_ROLE_ERROR";

export const UPDATE_ROLE_PERMISSIONS = "UPDATE_ROLE_PERMISSIONS";
export const UPDATE_ROLE_PERMISSIONS_LOADING =
  "UPDATE_ROLE_PERMISSIONS_LOADING";
export const UPDATE_ROLE_PERMISSIONS_SUCCESS =
  "UPDATE_ROLE_PERMISSIONS_SUCCESS";
export const UPDATE_ROLE_PERMISSIONS_ERROR = "UPDATE_ROLE_PERMISSIONS_ERROR";

export const DELETE_ROLE = "DELETE_ROLE";
export const DELETE_ROLE_LOADING = "DELETE_ROLE_LOADING";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_ERROR = "DELETE_ROLE_ERROR";

// Update Notification Action
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";

// Set Actions (Socket)

// Set Users Action
export const SET_USERS = "SET_USERS";
export const SET_USERS_SUCCESS = "SET_USERS_SUCCESS";

// Set Projects Action
export const SET_PROJECTS = "SET_PROJECTS";
export const SET_PROJECTS_SUCCESS = "SET_PROJECTS_SUCCESS";

// Set Tasks Action
export const SET_TASKS = "SET_TASKS";
export const TASKS_LOADING = "TASKS_LOADING";
export const SET_TASKS_SUCCESS = "SET_TASKS_SUCCESS";
export const SET_ADD_TASK_SUCCESS = "SET_ADD_TASK_SUCCESS";

// Set Notifications Action
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const SET_NOTIFICATIONS_SUCCESS = "SET_NOTIFICATIONS_SUCCESS";

// Set Roles Action
export const SET_ROLES = "SET_ROLES";
export const SET_ROLES_SUCCESS = "SET_ROLES_SUCCESS";

// Set Selected

// Select Project Action
export const SET_SELECTED_PROJECT = "SET_SELECTED_PROJECT";
export const SET_SELECTED_PROJECT_LOADING = "SET_SELECTED_PROJECT_LOADING";

// Set Project Status
//TODO: DELETE THIS CODE IF ALL WORKS FINE
// export const SET_IS_ON_GOING_PROJECT = "SET_IS_ON_GOING_PROJECT";

// Select Task Action
export const SET_SELECTED_TASK = "SET_SELECTED_TASK";

//set filtered options
export const SET_FILTERED_OPTIONS = "SET_FILTERED_OPTIONS";
export const SET_FILTERED_OPTIONS_SUCCESS = "SET_FILTERED_OPTIONS_SUCCESS";

// Select Role Action
export const SET_SELECTED_ROLE = "SET_SELECTED_ROLE";

// Set Sockets

// Set Task Socket
export const SET_TASK_SOCKET = "SET_TASK_SOCKET";

// Set Notification Socket
export const SET_NOTIFICATION_SOCKET = "SET_NOTIFICATION_SOCKET";

// Set Project Socket
export const SET_PROJECT_SOCKET = "SET_PROJECT_SOCKET";

// Set User Socket
export const SET_USER_SOCKET = "SET_USER_SOCKET";

// Set Search Socket
export const SET_SEARCH_SOCKET = "SET_SEARCH_SOCKET";

// Set Role Socket
export const SET_ROLE_SOCKET = "SET_ROLE_SOCKET";

// Socket Loading
export const SOCKET_LOADING = "SOCKET_LOADING";

//comment notification
export const SET_COMMENT_NOTIFICATIONS = "SET_COMMENT_NOTIFICATIONS";

//set common notification
export const SET_COMMON_NOTIFICATIONS = "SET_COMMON_NOTIFICATIONS";

export const SET_TEMP_TASKS = "SET_TEMP_TASKS";
