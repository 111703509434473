import {
  CREATE_ORG_ACCOUNT,
  CREATE_ORG_ACCOUNT_LOADING,
  CREATE_ORG_ACCOUNT_ERROR,
  INVITE_USER_LOADING,
  INVITE_USER_SUCCESS,
  INVITE_USER_ERROR,
  RESEND_INVITE_LOADING,
  RESEND_INVITE_ERROR,
  RESEND_INVITE_SUCCESS,
  REMOVE_USER_LOADING,
  REMOVE_USER_ERROR,
  REMOVE_USER_SUCCESS,
  VERIFY_USER_INVITATION_LOADING,
  VERIFY_USER_INVITAION_SUCCESS,
  VERIFY_USER_INVITAION_ERROR,
  UPDATE_INVITED_USER_LOADING,
  UPDATE_INVITED_USER_SUCCESS,
  UPDATE_INVITED_USER_ERROR,
} from "../types";

let initialState = {
  error: "",
  loading: false,
  inviteUserLoading: false,
  resendInviteLoading: false,
  removeUserLoading: false,
  verificationLoading: false,
  invalidUserError: "",
  // updateInvitedUserLoading: false,
  organization: {},
  verifiedData: {},
  verificationSuccess: false,
  updateInvitedUserSuccess: false,
  inviteUserSuccess: false,
  resendInviteSuccess: false,
  removeUserSuccess: false,
};

export const organizationReducer = (state = initialState, action) => {
  switch (action.type) {
    // Create Account Actions
    case CREATE_ORG_ACCOUNT_LOADING:
      return {
        ...state,
        loading: action.payload,
        error: "",
      };
    case CREATE_ORG_ACCOUNT:
      return {
        ...state,
        loading: false,
        error: "",
        organization: action.payload,
      };
    case CREATE_ORG_ACCOUNT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        organization: {},
      };

    // Invite Organization User Actions
    case INVITE_USER_LOADING:
      return {
        ...state,
        inviteUserLoading: action.payload,
        error: "",
        inviteUserSuccess: false,
      };

    case INVITE_USER_SUCCESS:
      return {
        ...state,
        inviteUserLoading: false,
        error: "",
        inviteUserSuccess: true,
      };

    case INVITE_USER_ERROR:
      return {
        ...state,
        inviteUserLoading: false,
        error: action.payload,
        inviteUserSuccess: false,
      };

    // Resend Invite Organization User Actions
    case RESEND_INVITE_LOADING:
      return {
        ...state,
        resendInviteLoading: action.payload,
        error: "",
        resendInviteSuccess: false,
      };

    case RESEND_INVITE_SUCCESS:
      return {
        ...state,
        resendInviteLoading: false,
        error: "",
        resendInviteSuccess: true,
      };

    case RESEND_INVITE_ERROR:
      return {
        ...state,
        resendInviteLoading: false,
        error: action.payload,
        resendInviteSuccess: false,
      };

    // Remove Organization User Actions
    case REMOVE_USER_LOADING:
      return {
        ...state,
        removeUserLoading: action.payload,
        error: "",
        removeUserSuccess: false,
      };

    case REMOVE_USER_SUCCESS:
      return {
        ...state,
        removeUserLoading: false,
        error: "",
        removeUserSuccess: true,
        // orgUserId: "",
      };

    case REMOVE_USER_ERROR:
      return {
        ...state,
        removeUserLoading: false,
        error: action.payload,
        removeUserSuccess: false,
      };

    // Verify Organization And User Actions
    case VERIFY_USER_INVITATION_LOADING:
      return {
        ...state,
        verificationLoading: action.payload,
        invalidUserError: "",
        verificationSuccess: false,
      };

    case VERIFY_USER_INVITAION_SUCCESS:
      return {
        ...state,
        verificationLoading: false,
        verifiedData: action.payload,
        invalidUserError: "",
        verificationSuccess: true,
      };

    case VERIFY_USER_INVITAION_ERROR:
      return {
        ...state,
        verificationLoading: false,
        invalidUserError: action.payload,
        verificationSuccess: false,
      };

    // Update Invited User Actions
    case UPDATE_INVITED_USER_LOADING:
      return {
        ...state,
        loading: action.payload,
        error: "",
        updateInvitedUserSuccess: false,
      };

    case UPDATE_INVITED_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        updateInvitedUserSuccess: true,
      };

    case UPDATE_INVITED_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        updateInvitedUserSuccess: false,
      };

    default:
      return {
        ...state,
      };
  }
};
