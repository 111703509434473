import {
  ADD_PROJECT_ERROR,
  ADD_PROJECT_LOADING,
  ADD_PROJECT_SUCCESS,
  EDIT_PROJECT_ERROR,
  EDIT_PROJECT_LOADING,
  EDIT_PROJECT_SUCCESS,
  ADD_NOTE_ERROR,
  ADD_NOTE_LOADING,
  ADD_NOTE_SUCCESS,
  EDIT_NOTE_LOADING,
  EDIT_NOTE_ERROR,
  EDIT_NOTE_SUCCESS,
  DELETE_NOTE_LOADING,
  DELETE_NOTE_ERROR,
  DELETE_NOTE_SUCCESS,
  ADD_FILE_LOADING,
  ADD_FILE_SUCCESS,
  ADD_FILE_ERROR,
  REMOVE_FILE_LOADING,
  REMOVE_FILE_SUCCESS,
  REMOVE_FILE_ERROR,
  INVITE_PROJECT_USER_ERROR,
  INVITE_PROJECT_USER_LOADING,
  INVITE_PROJECT_USER_SUCCESS,
  UPDATE_PROJECT_USER_ROLE_LOADING,
  UPDATE_PROJECT_USER_ROLE_ERROR,
  UPDATE_PROJECT_USER_ROLE_SUCCESS,
  RESEND_PROJECT_INVITE_LOADING,
  RESEND_PROJECT_INVITE_ERROR,
  RESEND_PROJECT_INVITE_SUCCESS,
  REMOVE_PROJECT_USER_ERROR,
  REMOVE_PROJECT_USER_LOADING,
  REMOVE_PROJECT_USER_SUCCESS,
  VERIFY_INVITATION_LOADING,
  VERIFY_INVITATION_SUCCESS,
  VERIFY_INVITATION_ERROR,
  CANCEL_PROJECT_INVITATION_ACTION,
  ACCEPT_REJECT_PROJECT_INVITATION_LOADING,
  ACCEPT_REJECT_PROJECT_INVITATION_SUCCESS,
  ACCEPT_REJECT_PROJECT_INVITATION_ERROR,
  PREVIEW_PROJECT_FILE_LOADING,
  PREVIEW_PROJECT_FILE_SUCCESS,
  PREVIEW_PROJECT_FILE_ERROR,
  SET_PROJECTS_SUCCESS,
  SET_SELECTED_PROJECT,
  SET_SELECTED_PROJECT_LOADING,
  //TODO: DELETE THIS CODE IF ALL WORKS FINE
  // SET_IS_ON_GOING_PROJECT,
} from "../types";

let initialState = {
  error: "",
  addEditLoading: false, // Project Add/Edit Loading
  addEditNoteLoading: false,
  deleteNoteLoading: false,
  addFileLoading: false,
  removeFileLoading: false,
  inviteUserLoading: false,
  updateUserRoleLoading: false,
  resendInviteLoading: false,
  removeUserLoading: false,
  acceptRejectInvitationLoading: false,
  verifyInvitationSuccess: false,
  verifyInvitationError: null,
  previewFileLoading: false,
  projects:null,
  selectedProjectId: "",
  //TODO: DELETE THIS CODE IF ALL WORKS FINE
  // isOnGoingProject: "",
  invitedProject: {},
  previewFilePath: "",
  addProjectSuccess: false,
  editProjectSuccess: false,
  addEditNoteSuccess: false,
  deleteNoteSuccess: false,
  addFileSuccess: false,
  removeFileSuccess: false,
  inviteUserSuccess: false,
  acceptRejectInvitationSuccess: false,
  updateUserRoleSuccess: false,
  resendInviteSuccess: false,
  removeUserSuccess: false,
  previewFileSuccess: false,
  selectedProjectLoading: false,
};

export const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    // Add Project Actions
    case ADD_PROJECT_LOADING:
      return {
        ...state,
        addEditLoading: action.payload,
        error: "",
        addProjectSuccess: false,
      };
    case ADD_PROJECT_SUCCESS:
      return {
        ...state,
        addEditLoading: false,
        error: "",
        addProjectSuccess: true,
      };
    case ADD_PROJECT_ERROR:
      return {
        ...state,
        addEditLoading: false,
        error: action.payload,
        addProjectSuccess: false,
      };

    // Edit Project Actions
    case EDIT_PROJECT_LOADING:
      return {
        ...state,
        addEditLoading: action.payload,
        error: "",
        editProjectSuccess: false,
      };
    case EDIT_PROJECT_SUCCESS:
      return {
        ...state,
        addEditLoading: false,
        error: "",
        editProjectSuccess: true,
      };
    case EDIT_PROJECT_ERROR:
      return {
        ...state,
        addEditLoading: false,
        error: action.payload,
        editProjectSuccess: false,
      };

    // Add Note Actions
    case ADD_NOTE_LOADING:
      return {
        ...state,
        addEditNoteLoading: action.payload,
        error: "",
        addEditNoteSuccess: false,
      };
    case ADD_NOTE_SUCCESS:
      return {
        ...state,
        addEditNoteLoading: false,
        error: "",
        addEditNoteSuccess: true,
      };
    case ADD_NOTE_ERROR:
      return {
        ...state,
        addEditNoteLoading: false,
        error: action.payload,
        addEditNoteSuccess: false,
      };

    // Edit Note Actions
    case EDIT_NOTE_LOADING:
      return {
        ...state,
        addEditNoteLoading: action.payload,
        error: "",
        addEditNoteSuccess: false,
      };
    case EDIT_NOTE_SUCCESS:
      return {
        ...state,
        addEditNoteLoading: false,
        error: "",
        addEditNoteSuccess: true,
      };
    case EDIT_NOTE_ERROR:
      return {
        ...state,
        addEditNoteLoading: false,
        error: action.payload,
        addEditNoteSuccess: false,
      };

    // Delete Note Actions
    case DELETE_NOTE_LOADING:
      return {
        ...state,
        deleteNoteLoading: action.payload,
        error: "",
        deleteNoteSuccess: false,
      };
    case DELETE_NOTE_SUCCESS:
      return {
        ...state,
        deleteNoteLoading: false,
        error: "",
        deleteNoteSuccess: true,
      };
    case DELETE_NOTE_ERROR:
      return {
        ...state,
        deleteNoteLoading: false,
        error: action.payload,
        deleteNoteSuccess: false,
      };

    // Add Project File Actions
    case ADD_FILE_LOADING:
      return {
        ...state,
        addFileLoading: action.payload,
        error: "",
        addFileSuccess: false,
      };
    case ADD_FILE_SUCCESS:
      return {
        ...state,
        addFileLoading: false,
        error: "",
        addFileSuccess: true,
      };
    case ADD_FILE_ERROR:
      return {
        ...state,
        addFileLoading: false,
        error: action.payload,
        addFileSuccess: false,
      };

    // Remove Project File Actions
    case REMOVE_FILE_LOADING:
      return {
        ...state,
        removeFileLoading: action.payload,
        error: "",
        removeFileSuccess: false,
      };
    case REMOVE_FILE_SUCCESS:
      return {
        ...state,
        removeFileLoading: false,
        error: "",
        removeFileSuccess: true,
      };
    case REMOVE_FILE_ERROR:
      return {
        ...state,
        removeFileLoading: false,
        error: action.payload,
        removeFileSuccess: false,
      };

    // Invite Project User Actions
    case INVITE_PROJECT_USER_LOADING:
      return {
        ...state,
        inviteUserLoading: action.payload,
        error: "",
        inviteUserSuccess: false,
      };
    case INVITE_PROJECT_USER_SUCCESS:
      return {
        ...state,
        inviteUserLoading: false,
        error: "",
        inviteUserSuccess: true,
      };
    case INVITE_PROJECT_USER_ERROR:
      return {
        ...state,
        inviteUserLoading: false,
        error: action.payload,
        inviteUserSuccess: false,
      };

    // Project User Role Update
    case UPDATE_PROJECT_USER_ROLE_LOADING:
      return {
        ...state,
        updateUserRoleLoading: action.payload,
        error: "",
        updateUserRoleSuccess: false,
      };
    case UPDATE_PROJECT_USER_ROLE_SUCCESS:
      return {
        ...state,
        updateUserRoleLoading: false,
        error: "",
        updateUserRoleSuccess: true,
      };
    case UPDATE_PROJECT_USER_ROLE_ERROR:
      return {
        ...state,
        updateUserRoleLoading: false,
        error: action.payload,
        updateUserRoleSuccess: false,
      };

    // Resend Invite User Actions
    case RESEND_PROJECT_INVITE_LOADING:
      return {
        ...state,
        resendInviteLoading: action.payload,
        error: "",
        resendInviteSuccess: false,
      };
    case RESEND_PROJECT_INVITE_SUCCESS:
      return {
        ...state,
        resendInviteLoading: false,
        error: "",
        resendInviteSuccess: true,
      };
    case RESEND_PROJECT_INVITE_ERROR:
      return {
        ...state,
        resendInviteLoading: false,
        error: action.payload,
        resendInviteSuccess: false,
      };

    // Remove Project User Actions
    case REMOVE_PROJECT_USER_LOADING:
      return {
        ...state,
        removeUserLoading: action.payload,
        error: "",
        removeUserSuccess: false,
      };
    case REMOVE_PROJECT_USER_SUCCESS:
      return {
        ...state,
        removeUserLoading: false,
        error: "",
        removeUserSuccess: true,
      };
    case REMOVE_PROJECT_USER_ERROR:
      return {
        ...state,
        removeUserLoading: false,
        error: action.payload,
        removeUserSuccess: false,
      };

    // Verify Project Invitation
    case VERIFY_INVITATION_LOADING:
      return {
        ...state,
        verifyInvitationSuccess: false,
        verifyInvitationError: null,
      };

    case VERIFY_INVITATION_SUCCESS:
      return {
        ...state,
        verifyInvitationSuccess: true,
        verifyInvitationError: null,
        invitedProject: action.payload,
      };

    case VERIFY_INVITATION_ERROR:
      return {
        ...state,
        verifyInvitationSuccess: false,
        verifyInvitationError: action.payload,
        invitedProject: {},
      };

    case CANCEL_PROJECT_INVITATION_ACTION:
      return {
        ...state,
        verifyInvitationSuccess: false,
        verifyInvitationError: null,
        invitedProject: {},
      };

    // Accept/Reject Project Invitation
    case ACCEPT_REJECT_PROJECT_INVITATION_LOADING:
      return {
        ...state,
        acceptRejectInvitationLoading: action.payload,
        acceptRejectInvitationSuccess: false,
      };

    case ACCEPT_REJECT_PROJECT_INVITATION_SUCCESS:
      return {
        ...state,
        acceptRejectInvitationLoading: false,
        acceptRejectInvitationSuccess: true,
      };

    case ACCEPT_REJECT_PROJECT_INVITATION_ERROR:
      return {
        ...state,
        acceptRejectInvitationLoading: false,
        acceptRejectInvitationSuccess: true,
      };

    // Preview Project File Actions
    case PREVIEW_PROJECT_FILE_LOADING:
      return {
        ...state,
        previewFileLoading: action.payload,
        previewFilePath: "",
        previewFileSuccess: false,
      };
    case PREVIEW_PROJECT_FILE_SUCCESS:
      return {
        ...state,
        previewFileLoading: false,
        previewFilePath: action.payload,
        previewFileSuccess: true,
      };
    case PREVIEW_PROJECT_FILE_ERROR:
      return {
        ...state,
        previewFileLoading: false,
        previewFilePath: "",
        previewFileSuccess: false,
      };

    // Set Project Action
    case SET_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.payload,
      };

    // Set Selected Project Action
    case SET_SELECTED_PROJECT_LOADING:
      return {
        ...state,
        selectedProjectLoading: action.payload,
      };
    case SET_SELECTED_PROJECT:
      return {
        ...state,
        selectedProjectId: action.payload,

        // acceptRejectInvitationSuccess: false,
      };

    //TODO: DELETE THIS CODE IF ALL WORKS FINE
    // Set Is On Going Project Action
    // case SET_IS_ON_GOING_PROJECT:
    //   return {
    //     ...state,
    //     isOnGoingProject: action.payload,
    //   };

    default:
      return state;
  }
};
