import { put, call, takeLatest } from "redux-saga/effects";

import { toast } from "react-toastify";

import {
  ADD_PROJECT,
  ADD_PROJECT_LOADING,
  ADD_PROJECT_ERROR,
  ADD_PROJECT_SUCCESS,
  EDIT_PROJECT,
  EDIT_PROJECT_LOADING,
  EDIT_PROJECT_ERROR,
  EDIT_PROJECT_SUCCESS,
  ADD_NOTE,
  ADD_NOTE_LOADING,
  ADD_NOTE_SUCCESS,
  ADD_NOTE_ERROR,
  EDIT_NOTE,
  EDIT_NOTE_LOADING,
  EDIT_NOTE_SUCCESS,
  EDIT_NOTE_ERROR,
  DELETE_NOTE,
  DELETE_NOTE_LOADING,
  DELETE_NOTE_SUCCESS,
  DELETE_NOTE_ERROR,
  ADD_FILE,
  ADD_FILE_LOADING,
  ADD_FILE_SUCCESS,
  ADD_FILE_ERROR,
  REMOVE_FILE,
  REMOVE_FILE_LOADING,
  REMOVE_FILE_SUCCESS,
  REMOVE_FILE_ERROR,
  INVITE_PROJECT_USER,
  INVITE_PROJECT_USER_SUCCESS,
  INVITE_PROJECT_USER_LOADING,
  INVITE_PROJECT_USER_ERROR,
  UPDATE_PROJECT_USER_ROLE,
  UPDATE_PROJECT_USER_ROLE_LOADING,
  UPDATE_PROJECT_USER_ROLE_ERROR,
  UPDATE_PROJECT_USER_ROLE_SUCCESS,
  RESEND_PROJECT_INVITE,
  RESEND_PROJECT_INVITE_LOADING,
  RESEND_PROJECT_INVITE_SUCCESS,
  RESEND_PROJECT_INVITE_ERROR,
  REMOVE_PROJECT_USER,
  REMOVE_PROJECT_USER_SUCCESS,
  REMOVE_PROJECT_USER_LOADING,
  REMOVE_PROJECT_USER_ERROR,
  VERIFY_INVITATION,
  VERIFY_INVITATION_LOADING,
  VERIFY_INVITATION_ERROR,
  VERIFY_INVITATION_SUCCESS,
  CANCEL_PROJECT_INVITATION,
  CANCEL_PROJECT_INVITATION_ACTION,
  ACCEPT_REJECT_PROJECT_INVITATION,
  ACCEPT_REJECT_PROJECT_INVITATION_LOADING,
  ACCEPT_REJECT_PROJECT_INVITATION_ERROR,
  ACCEPT_REJECT_PROJECT_INVITATION_SUCCESS,
  SET_PROJECTS,
  SET_PROJECTS_SUCCESS,
  PREVIEW_PROJECT_FILE,
  PREVIEW_PROJECT_FILE_LOADING,
  PREVIEW_PROJECT_FILE_SUCCESS,
  PREVIEW_PROJECT_FILE_ERROR,
  DOWNLOAD_PROJECT_FILE,
  // DOWNLOAD_PROJECT_FILE_SUCCESS
} from "../types";

import {
  addApi,
  editApi,
  addNoteApi,
  editNoteApi,
  deleteNoteApi,
  addFileApi,
  removeFileApi,
  inviteUserApi,
  verifyInvitationApi,
  acceptRejectInvitationApi,
  updateUserRoleApi,
  resendInviteApi,
  removeUserApi,
  previewFileApi,
  downloadFileApi,
} from "../apis/project";

import { getErrorMsg } from "../../lib/helperFunctions";

//Project
function* addSaga(data) {
  yield put({ type: ADD_PROJECT_LOADING, payload: true });

  try {
    let res = yield call(addApi, data.payload);

    // console.log(res);
    if (res.status === 0) {
      yield put({ type: ADD_PROJECT_SUCCESS, payload: res.result });
      toast.success(res.message);
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: ADD_PROJECT_ERROR, payload: errorMsg });
      toast.error(errorMsg);
    }
  } catch (error) {
    yield put({ type: ADD_PROJECT_ERROR, payload: error.toString() });
    toast.error(error.toString());
  }
}

function* editSaga(data) {
  yield put({ type: EDIT_PROJECT_LOADING, payload: true });

  try {
    let res = yield call(editApi, data.payload);

    // console.log(res);

    if (res.status === 0) {
      toast.success(res.message);
      yield put({ type: EDIT_PROJECT_SUCCESS, payload: res.result });
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: EDIT_PROJECT_ERROR, payload: errorMsg });
      toast.error(errorMsg);
    }
  } catch (error) {
    yield put({ type: EDIT_PROJECT_ERROR, payload: error.toString() });
    toast.error(error.toString());
  }
}

// Note
function* addNoteSaga(data) {
  yield put({ type: ADD_NOTE_LOADING, payload: true });

  try {
    let res = yield call(addNoteApi, data.payload);

    // console.log(res);

    if (res.status === 0) {
      yield put({ type: ADD_NOTE_SUCCESS, payload: res.result });
      toast.success(res.message);
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: ADD_NOTE_ERROR, payload: errorMsg });
      toast.error(errorMsg);
    }
  } catch (error) {
    yield put({ type: ADD_NOTE_ERROR, payload: error.toString() });
    toast.error(error.toString());
  }
}

function* editNoteSaga(data) {
  yield put({ type: EDIT_NOTE_LOADING, payload: true });

  try {
    let res = yield call(editNoteApi, data.payload);

    if (res.status === 0) {
      yield put({ type: EDIT_NOTE_SUCCESS, payload: res.result });
      toast.success(res.message);
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: EDIT_NOTE_ERROR, payload: errorMsg });
      toast.error(errorMsg);
    }
  } catch (error) {
    yield put({ type: EDIT_NOTE_ERROR, payload: error.toString() });
    toast.error(error.toString());
  }
}

function* deleteNoteSaga(data) {
  yield put({ type: DELETE_NOTE_LOADING, payload: true });

  try {
    let res = yield call(deleteNoteApi, data.payload);

    // console.log(res);

    if (res.status === 0) {
      yield put({ type: DELETE_NOTE_SUCCESS, payload: res.result });
      toast.success(res.message);
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: DELETE_NOTE_ERROR, payload: errorMsg });
      toast.error(errorMsg);
    }
  } catch (error) {
    yield put({ type: DELETE_NOTE_ERROR, payload: error.toString() });
    toast.error(error.toString());
  }
}

function* addFileSaga(data) {
  yield put({ type: ADD_FILE_LOADING, payload: true });

  try {
    let res = yield call(addFileApi, data.payload);

    // console.log(res);

    if (res.status === 0) {
      yield put({ type: ADD_FILE_SUCCESS, payload: res.result });
      toast.success(res.message);
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: ADD_FILE_ERROR, payload: errorMsg });
      toast.error(errorMsg);
    }
  } catch (error) {
    yield put({ type: ADD_FILE_ERROR, payload: error.toString() });
    toast.error(error.toString());
  }
}

function* removeFileSaga(data) {
  yield put({ type: REMOVE_FILE_LOADING, payload: true });

  try {
    let res = yield call(removeFileApi, data.payload);

    // console.log(res);

    if (res.status === 0) {
      yield put({ type: REMOVE_FILE_SUCCESS, payload: res.result });
      toast.success(res.message);
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: REMOVE_FILE_ERROR, payload: errorMsg });
      toast.error(errorMsg);
    }
  } catch (error) {
    yield put({ type: REMOVE_FILE_ERROR, payload: error.toString() });
    toast.error(error.toString());
  }
}

function* inviteUserSaga(data) {
  yield put({ type: INVITE_PROJECT_USER_LOADING, payload: true });

  try {
    let res = yield call(inviteUserApi, data.payload);

    // console.log(res);

    if (res.status === 0) {
      yield put({ type: INVITE_PROJECT_USER_SUCCESS, payload: res.result });
      toast.success(res.message);
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: INVITE_PROJECT_USER_ERROR, payload: errorMsg });
      toast.error(errorMsg);
    }
  } catch (error) {
    yield put({ type: INVITE_PROJECT_USER_ERROR, payload: error.toString() });
    toast.error(error.toString());
  }
}

function* verifyInvitationSaga(data) {
  yield put({
    type: VERIFY_INVITATION_LOADING,
    payload: true,
  });

  try {
    let res = yield call(verifyInvitationApi, data.payload);

    // console.log(res);

    if (res.status === 0) {
      yield put({ type: VERIFY_INVITATION_SUCCESS, payload: res.result });
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: VERIFY_INVITATION_ERROR, payload: errorMsg });
    }
  } catch (error) {
    yield put({ type: VERIFY_INVITATION_ERROR, payload: error.toString() });
  }
}

function* cancelInvitationSaga() {
  yield put({
    type: CANCEL_PROJECT_INVITATION_ACTION,
  });
}

function* acceptRejectInvitationSaga(data) {
  yield put({
    type: ACCEPT_REJECT_PROJECT_INVITATION_LOADING,
    payload: true,
  });

  try {
    let res = yield call(acceptRejectInvitationApi, data.payload);

    // console.log(res);

    if (res.status === 0) {
      yield put({
        type: ACCEPT_REJECT_PROJECT_INVITATION_SUCCESS,
        payload: res.result,
      });
      toast.success(res.message);
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({
        type: ACCEPT_REJECT_PROJECT_INVITATION_ERROR,
        payload: errorMsg,
      });
      // toast.error(errorMsg);
    }
  } catch (error) {
    yield put({
      type: ACCEPT_REJECT_PROJECT_INVITATION_ERROR,
      payload: error.toString(),
    });
    // toast.error(error.toString());
  }
}

function* updateUserRoleSaga(data) {
  yield put({ type: UPDATE_PROJECT_USER_ROLE_LOADING, payload: true });

  try {
    let res = yield call(updateUserRoleApi, data.payload);

    // console.log(res);

    if (res.status === 0) {
      yield put({
        type: UPDATE_PROJECT_USER_ROLE_SUCCESS,
        payload: res.result,
      });
      toast.success(res.message);
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: UPDATE_PROJECT_USER_ROLE_ERROR, payload: errorMsg });
      toast.error(errorMsg);
    }
  } catch (error) {
    yield put({
      type: UPDATE_PROJECT_USER_ROLE_ERROR,
      payload: error.toString(),
    });
    toast.error(error.toString());
  }
}

function* resendInviteUserSaga(data) {
  yield put({ type: RESEND_PROJECT_INVITE_LOADING, payload: true });

  try {
    let res = yield call(resendInviteApi, data.payload);

    // console.log(res);

    if (res.status === 0) {
      yield put({ type: RESEND_PROJECT_INVITE_SUCCESS, payload: res.result });
      toast.success(res.message);
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: RESEND_PROJECT_INVITE_ERROR, payload: errorMsg });
      toast.error(errorMsg);
    }
  } catch (error) {
    yield put({ type: RESEND_PROJECT_INVITE_ERROR, payload: error.toString() });
    toast.error(error.toString());
  }
}

function* removeUserSaga(data) {
  yield put({ type: REMOVE_PROJECT_USER_LOADING, payload: true });

  try {
    let res = yield call(removeUserApi, data.payload);

    // console.log(res);

    if (res.status === 0) {
      yield put({ type: REMOVE_PROJECT_USER_SUCCESS, payload: res.result._id });
      toast.success(res.message);
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: REMOVE_PROJECT_USER_ERROR, payload: errorMsg });
      toast.error(errorMsg);
    }
  } catch (error) {
    yield put({ type: REMOVE_PROJECT_USER_ERROR, payload: error.toString() });
    toast.error(error.toString());
  }
}

function* setProjectsSaga(data) {
  yield put({ type: SET_PROJECTS_SUCCESS, payload: data.payload });
}

function* previewFileSaga(data) {
  yield put({ type: PREVIEW_PROJECT_FILE_LOADING, payload: true });
  try {
    let res = yield call(previewFileApi, data.payload);

    // console.log("Res: ", res);

    if (res) {
      yield put({ type: PREVIEW_PROJECT_FILE_SUCCESS, payload: res.result });
    } else {
      yield put({ type: PREVIEW_PROJECT_FILE_ERROR, payload: "" });
      toast.error("Something went wrong!");
    }
  } catch (error) {
    toast.error(error.toString());
  }
}

function* downloadFileSaga(data) {
  try {
    yield call(downloadFileApi, data.payload);
  } catch (error) {
    toast.error(error.toString());
  }
}

function* watchProjectSaga() {
  yield takeLatest(ADD_PROJECT, addSaga);
  yield takeLatest(EDIT_PROJECT, editSaga);
  yield takeLatest(ADD_NOTE, addNoteSaga);
  yield takeLatest(EDIT_NOTE, editNoteSaga);
  yield takeLatest(DELETE_NOTE, deleteNoteSaga);
  yield takeLatest(ADD_FILE, addFileSaga);
  yield takeLatest(REMOVE_FILE, removeFileSaga);
  yield takeLatest(INVITE_PROJECT_USER, inviteUserSaga);
  yield takeLatest(UPDATE_PROJECT_USER_ROLE, updateUserRoleSaga);
  yield takeLatest(RESEND_PROJECT_INVITE, resendInviteUserSaga);
  yield takeLatest(VERIFY_INVITATION, verifyInvitationSaga);
  yield takeLatest(CANCEL_PROJECT_INVITATION, cancelInvitationSaga);
  yield takeLatest(
    ACCEPT_REJECT_PROJECT_INVITATION,
    acceptRejectInvitationSaga
  );
  yield takeLatest(REMOVE_PROJECT_USER, removeUserSaga);
  yield takeLatest(SET_PROJECTS, setProjectsSaga);
  yield takeLatest(PREVIEW_PROJECT_FILE, previewFileSaga);
  yield takeLatest(DOWNLOAD_PROJECT_FILE, downloadFileSaga);
}

export default watchProjectSaga;
