import { put, call, takeLatest } from "redux-saga/effects";

import { toast } from "react-toastify";

import {
  CREATE_ORG_ACCOUNT,
  CREATE_ORG_ACCOUNT_ERROR,
  CREATE_ORG_ACCOUNT_LOADING,
  INVITE_USER,
  INVITE_USER_LOADING,
  INVITE_USER_ERROR,
  INVITE_USER_SUCCESS,
  RESEND_INVITE,
  RESEND_INVITE_LOADING,
  RESEND_INVITE_SUCCESS,
  RESEND_INVITE_ERROR,
  REMOVE_USER,
  REMOVE_USER_LOADING,
  REMOVE_USER_ERROR,
  REMOVE_USER_SUCCESS,
  VERIFY_USER_INVITATION,
  VERIFY_USER_INVITATION_LOADING,
  VERIFY_USER_INVITAION_SUCCESS,
  VERIFY_USER_INVITAION_ERROR,
  UPDATE_INVITED_USER,
  UPDATE_INVITED_USER_ERROR,
  UPDATE_INVITED_USER_LOADING,
  UPDATE_INVITED_USER_SUCCESS,
} from "../types";

import {
  createApi,
  inviteUserApi,
  resendInviteApi,
  removeUserApi,
  verifyOrganizationApi,
  updateInvitedUserApi,
  verifyUserApi,
} from "../apis/organization";

import { getErrorMsg } from "../../lib/helperFunctions";

function* createSaga(data) {
  yield put({ type: CREATE_ORG_ACCOUNT_LOADING, payload: true });

  try {
    const { history } = data.payload;
    delete data.payload.history;

    let res = yield call(createApi, data.payload);

    if (res.status === 0) {
      toast.success(res.message);
      history.push("/login");
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({
        type: CREATE_ORG_ACCOUNT_ERROR,
        payload: errorMsg,
      });
      toast.error(errorMsg);
    }
  } catch (error) {
    yield put({ type: CREATE_ORG_ACCOUNT_ERROR, payload: error.toString() });
    toast.error(error.toString());
  }
}

function* inviteUserSaga(data) {
  yield put({ type: INVITE_USER_LOADING, payload: true });
  try {
    let res = yield call(inviteUserApi, data.payload);

    if (res.status === 0) {
      toast.success(res.message);
      yield put({
        type: INVITE_USER_SUCCESS,
        payload: res.result,
      });
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: INVITE_USER_ERROR, payload: errorMsg });
      toast.error(errorMsg);
    }
  } catch (error) {
    yield put({
      type: INVITE_USER_ERROR,
      payload: error.toString(),
    });
    toast.error(error.toString());
  }
}

function* resendInviteSaga(data) {
  yield put({ type: RESEND_INVITE_LOADING, payload: true });
  try {
    let res = yield call(resendInviteApi, data.payload);

    if (res.status === 0) {
      toast.success(res.message);
      yield put({
        type: RESEND_INVITE_SUCCESS,
        payload: res.result,
      });
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: RESEND_INVITE_ERROR, payload: errorMsg });
      toast.error(errorMsg);
    }
  } catch (error) {
    yield put({
      type: RESEND_INVITE_ERROR,
      payload: error.toString(),
    });
    toast.error(error.toString());
  }
}

function* removeUserSaga(data) {
  yield put({ type: REMOVE_USER_LOADING, payload: true });
  try {
    let res = yield call(removeUserApi, data.payload);

    // console.log("Res: ", res);

    if (res.status === 0) {
      toast.success(res.message);
      yield put({
        type: REMOVE_USER_SUCCESS,
        payload: res.result,
      });
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: REMOVE_USER_ERROR, payload: errorMsg });
      toast.error(errorMsg);
    }
  } catch (error) {
    yield put({
      type: REMOVE_USER_ERROR,
      payload: error.toString(),
    });
    toast.error(error.toString());
  }
}

function* verifyUserInvitationSaga(data) {
  yield put({ type: VERIFY_USER_INVITATION_LOADING, payload: true });
  try {
    let orgRes = yield call(verifyOrganizationApi, data.payload);

    if (orgRes.status === 0) {
      let { data: orgData } = orgRes.result;

      let verifiedData = {
        orgId: orgData.id,
        orgName: orgData.name,
      };
      let userRes = yield call(verifyUserApi, data.payload);
      // console.log(data.payload);
      if (userRes.status === 0) {
        let { data: userData } = userRes.result;
        // console.log(userRes.result);
        verifiedData["userId"] = userData.id;
        verifiedData["userEmail"] = userData.email;

        // toast.success(userRes.message);
        yield put({
          type: VERIFY_USER_INVITAION_SUCCESS,
          payload: verifiedData,
        });
      } else {
        let errorMsg = getErrorMsg(userRes.message);
        yield put({ type: VERIFY_USER_INVITAION_ERROR, payload: errorMsg });
        // toast.error(errorMsg);
      }
    } else {
      let errorMsg = getErrorMsg(orgRes.message);
      yield put({ type: VERIFY_USER_INVITAION_ERROR, payload: errorMsg });
      // toast.error(errorMsg);
    }
  } catch (error) {
    yield put({
      type: VERIFY_USER_INVITAION_ERROR,
      // payload: error.toString(),
    });
    // toast.error(error.toString());
  }
}

function* updateInvitedUserSaga(data) {
  yield put({ type: UPDATE_INVITED_USER_LOADING, payload: true });
  try {
    const { history } = data.payload;
    delete data.payload.history;

    let res = yield call(updateInvitedUserApi, data.payload);

    if (res.status === 0) {
      toast.success(res.message);
      yield put({
        type: UPDATE_INVITED_USER_SUCCESS,
        payload: res.result,
      });
      history.push("/login");
    } else {
      let errorMsg = getErrorMsg(res.message);
      yield put({ type: UPDATE_INVITED_USER_ERROR, payload: errorMsg });
      toast.error(errorMsg);
    }
  } catch (error) {
    yield put({
      type: UPDATE_INVITED_USER_ERROR,
      payload: error.toString(),
    });
    toast.error(error.toString());
  }
}

function* watchOrganizationSaga() {
  yield takeLatest(CREATE_ORG_ACCOUNT, createSaga);
  yield takeLatest(INVITE_USER, inviteUserSaga);
  yield takeLatest(RESEND_INVITE, resendInviteSaga);
  yield takeLatest(REMOVE_USER, removeUserSaga);
  yield takeLatest(VERIFY_USER_INVITATION, verifyUserInvitationSaga);
  yield takeLatest(UPDATE_INVITED_USER, updateInvitedUserSaga);
}

export default watchOrganizationSaga;
