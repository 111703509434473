import React from "react";
import {Placeholder, Menu, MenuItem } from "semantic-ui-react";

function HeaderLoader() {
  return (
    <div style={{marginBottom:"10px"}}>
    <Menu secondary style={{ backgroundColor: "#000" }}>
      <MenuItem>
        <Placeholder inverted fluid className="header-loader-logo">
          <Placeholder.Image />
        </Placeholder>
      </MenuItem>
      <MenuItem>
        <Placeholder inverted fluid className="header-loader-line">
          <Placeholder.Image />
        </Placeholder>
      </MenuItem>
      <MenuItem>
        <Placeholder
          inverted
          fluid
          className="header-loader-line project-dropdown"
        >
          <Placeholder.Image />
        </Placeholder>
      </MenuItem>
      <MenuItem>
        <Placeholder inverted fluid className="header-loader-line icons">
          <Placeholder.Image />
        </Placeholder>
      </MenuItem>
      <MenuItem>
        <Placeholder inverted fluid className="header-loader-line search">
          <Placeholder.Image />
        </Placeholder>
      </MenuItem>
      <MenuItem>
        <Placeholder
          inverted
          fluid
          className="header-loader-line notification-icons"
        >
          <Placeholder.Image />
        </Placeholder>
      </MenuItem>
      <MenuItem>
        <Placeholder inverted fluid className="header-loader-user-avatar">
          <Placeholder.Image />
        </Placeholder>
      </MenuItem>
      <MenuItem>
        <Placeholder
          inverted
          fluid
          className="header-loader-line user-dropdown"
        >
          <Placeholder.Image />
        </Placeholder>
      </MenuItem>
    </Menu>
    </div>
  );
}

export default HeaderLoader;
