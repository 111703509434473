let domainUrl = process.env.REACT_APP_API_BASE_URL;

const fileApi = {
  GET: (link, data) =>
    new Promise(async (resolve, reject) => {
      const token = localStorage.getItem("authToken");

      let url = domainUrl + link;

      fetch(url, {
        method: "GET",
        headers: {
          Authorization: token ? `jwt ${token}` : "",
        },
      })
        .then((response) => {
          if (response.status === 401) {
            window.location.pathname = "/login";
          }
          return response.blob();
        })
        .then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = data;
          a.click();
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    }),

  POST: (link, data) =>
    new Promise(async (resolve, reject) => {
      const token = localStorage.getItem("authToken");

      let url = domainUrl + link;

      fetch(url, {
        body: data,
        method: "POST",
        headers: {
          Authorization: token ? `jwt ${token}` : "",
        },
      })
        .then((response) => {
          if (response.status === 401) {
            window.location.pathname = "/login";
          }
          return response.json();
        })
        .then((responseText) => {
          resolve(responseText);
        })
        .catch((error) => {
          reject(error);
        });
    }),

  PUT: (link, data) =>
    new Promise(async (resolve, reject) => {
      const token = localStorage.getItem("authToken");
      const url = domainUrl + link;

      fetch(url, {
        body: data,
        method: "PUT",
        headers: {
          Authorization: token ? `jwt ${token}` : "",
        },
      })
        .then((response) => {
          if (response.status === 401) {
            window.location.pathname = "/login";
          }
          return response.json();
        })
        .then((responseText) => {
          resolve(responseText);
        })
        .catch((error) => {
          reject(error);
        });
    }),

  PATCH: (link, data) =>
    new Promise(async (resolve, reject) => {
      const token = localStorage.getItem("authToken");
      const url = domainUrl + link;

      fetch(url, {
        body: data,
        method: "PATCH",
        headers: {
          Authorization: token ? `jwt ${token}` : "",
        },
      })
        .then((response) => {
          if (response.status === 401) {
            window.location.pathname = "/login";
          }
          return response.json();
        })
        .then((responseText) => {
          resolve(responseText);
        })
        .catch((error) => {
          reject(error);
        });
    }),

  FILE_PREVIEW: (link, data) =>
    new Promise(async (resolve, reject) => {
      const token = localStorage.getItem("authToken");

      let url = domainUrl + link;

      fetch(url, {
        method: "GET",
        headers: {
          Authorization: token ? `jwt ${token}` : "",
        },
      })
        .then(async (response) => {
          if (response.status === 401) {
            window.location.pathname = "/login";
          }
          return response.json();
        })
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    }),
};

export default fileApi;
