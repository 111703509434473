import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGIN_LOADING,
  UPDATE_PROFILE_LOADING,
  UPDATE_PROFILE_ERROR,
  UPDATE_PROFILE_SUCCESS,
  CHANGE_PASSWORD_LOADING,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  RESET_PASSWORD_LOADING,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  VERIFY_RESET_TOKEN_LOADING,
  VERIFY_RESET_TOKEN_SUCCESS,
  VERIFY_RESET_TOKEN_ERROR,
  FORGOT_PASSWORD_LOADING,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_SUCCESS,
  // GET_ROLES_LOADING,
  // GET_ROLES_SUCCESS,
  // GET_ROLES_ERROR,
  SET_USERS_SUCCESS,
  SET_NOTIFICATIONS_SUCCESS,
  SEARCH,
  GET_CURRENT_USER_SUCCESS,
  LOGOUT_LOADING,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR
} from "../types";

let initialState = {
  error: "",
  loading: false,
  forgotPasswordLoading: false,
  changePasswordLoading: false,
  verifyResetTokenLoading: false,
  resetPasswordLoading: false,
  currentUser: {},
  users: [],
  notifications: [],
  // roles: [],
  searchResult: [],
  updateProfileSuccess: false,
  forgotPasswordSuccess: false,
  changePasswordSuccess: false,
  verifyResetTokenSuccess: false,
  resetPasswordSuccess: false,
  getRolesSuccess: false,
  logoutLoading: false,
  logoutSuccess: false
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    // Login Actions
    case LOGIN_LOADING:
      return {
        ...state,
        loading: action.payload,
        error: "",
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        currentUser: action.payload,
        // logoutSuccess:false
      };
    case LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        currentUser: {},
      };

    // Update Organization user Actions
    case UPDATE_PROFILE_LOADING:
      return {
        ...state,
        loading: action.payload,
        error: "",
        updateProfileSuccess: false,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        currentUser: action.payload,
        updateProfileSuccess: true,
      };
    case UPDATE_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        updateProfileSuccess: false,
      };

    // Change Password Actions
    case CHANGE_PASSWORD_LOADING:
      return {
        ...state,
        changePasswordLoading: action.payload,
        error: "",
        changePasswordSuccess: false,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordLoading: false,
        error: "",
        changePasswordSuccess: true,
      };
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePasswordLoading: false,
        error: action.payload,
        changePasswordSuccess: false,
      };

    // Verify Reset Token Actions
    case VERIFY_RESET_TOKEN_LOADING:
      return {
        ...state,
        verifyResetTokenLoading: action.payload,
        error: "",
        verifyResetTokenSuccess: false,
      };
    case VERIFY_RESET_TOKEN_SUCCESS:
      return {
        ...state,
        verifyResetTokenLoading: false,
        currentUser: action.payload,
        error: "",
        verifyResetTokenSuccess: true,
      };
    case VERIFY_RESET_TOKEN_ERROR:
      return {
        ...state,
        verifyResetTokenLoading: false,
        error: action.payload,
        verifyResetTokenSuccess: false,
      };

    // Reset Password Actions
    case RESET_PASSWORD_LOADING:
      return {
        ...state,
        resetPasswordLoading: action.payload,
        error: "",
        resetPasswordSuccess: false,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordLoading: false,
        error: "",
        resetPasswordSuccess: true,
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetPasswordLoading: false,
        error: action.payload,
        resetPasswordSuccess: false,
      };

    // Forgot Password Actions
    case FORGOT_PASSWORD_LOADING:
      return {
        ...state,
        forgotPasswordLoading: action.payload,
        error: "",
        forgotPasswordSuccess: false,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordLoading: false,
        error: "",
        forgotPasswordSuccess: true,
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        forgotPasswordLoading: false,
        error: action.payload,
        forgotPasswordSuccess: false,
      };

    case GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
      };

    // // Get User Roles Actions
    // case GET_ROLES_LOADING:
    //   return {
    //     ...state,
    //     // loading: action.payload,
    //     error: "",
    //     getRolesSuccess: false,
    //   };
    // case GET_ROLES_SUCCESS:
    //   return {
    //     ...state,
    //     // loading: false,
    //     error: "",
    //     roles: action.payload,
    //     getRolesSuccess: true,
    //   };
    // case GET_ROLES_ERROR:
    //   return {
    //     ...state,
    //     // loading: false,
    //     error: action.payload,
    //     roles: [],
    //     getRolesSuccess: false,
    //   };

    // SET USERS DATA
    case SET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
      };

    // SET NOTIFICATION DATA
    case SET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
      };

    case SEARCH:
      return {
        ...state,
        searchResult: action.payload,
      };
      
      //logout

      case LOGOUT_LOADING:
      return {
        ...state,
        logoutLoading: action.payload,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        logoutLoading:false,
        logoutSuccess: true
      };
    case LOGOUT_ERROR:
      return {
        ...state,
        logoutLoading: false,
        logoutSuccess: false,
      };

    default:
      return state;
  }
};
