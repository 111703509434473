import React from "react";
import { Placeholder, Grid } from "semantic-ui-react";

function FilesTabLoader() {
  let n = 5;
  return (
    <div style={{marginTop:"0.8em"}}>
      <Grid className="accordion-loader-grid">
        <Grid.Row>
          <Grid.Column width={1}></Grid.Column>
          <Grid.Column width={6}>
            <Placeholder>
              <Placeholder.Line length={"long"}></Placeholder.Line>
            </Placeholder>
          </Grid.Column>
          <Grid.Column width={4}>
            <Placeholder>
              <Placeholder.Line length={"full"}></Placeholder.Line>
            </Placeholder>
          </Grid.Column>
          <Grid.Column width={2}>
            <Placeholder>
              <Placeholder.Line length={"long"}></Placeholder.Line>
            </Placeholder>
          </Grid.Column>
          <Grid.Column width={1}>
            <Placeholder>
              <Placeholder.Line length={"full"}></Placeholder.Line>
            </Placeholder>
          </Grid.Column>
          <Grid.Column width={1} style={{ marginLeft: "1em" }}>
            <Placeholder>
              <Placeholder.Line length={"full"}></Placeholder.Line>
            </Placeholder>
          </Grid.Column>
        </Grid.Row>
        {[...Array(n)].map((e, i) => (
            <Grid.Row key={i}>
              <Grid.Column width={1}>
                <Placeholder style={{ height: "2.5em" }}>
                  <Placeholder.Image></Placeholder.Image>
                </Placeholder>
              </Grid.Column>
              <Grid.Column width={6}>
                <Placeholder>
                  <Placeholder.Line length={"long"}></Placeholder.Line>
                </Placeholder>
              </Grid.Column>
              <Grid.Column width={4}>
                <Placeholder>
                  <Placeholder.Line length={"full"}></Placeholder.Line>
                </Placeholder>
              </Grid.Column>
              <Grid.Column width={2}>
                <Placeholder>
                  <Placeholder.Line length={"long"}></Placeholder.Line>
                </Placeholder>
              </Grid.Column>
              <Grid.Column width={1}>
                <Placeholder>
                  <Placeholder.Line length={"full"}></Placeholder.Line>
                </Placeholder>
              </Grid.Column>
              <Grid.Column width={1} style={{ marginLeft: "1em" }}>
                <Placeholder>
                  <Placeholder.Line length={"full"}></Placeholder.Line>
                </Placeholder>
              </Grid.Column>
            </Grid.Row>
        ))}
      </Grid>
    </div>
  );
}

export default FilesTabLoader;
