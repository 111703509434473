import { fork } from "redux-saga/effects";

import watchOrganizationSaga from "./organization";
import watchUserSaga from "./user";
import watchProjectSaga from "./project";
import watchTaskSaga from "./task";
import watchRolesSaga from "./roles_permission";

export function* rootSaga() {
  yield fork(watchOrganizationSaga);
  yield fork(watchUserSaga);
  yield fork(watchProjectSaga);
  yield fork(watchTaskSaga);
  yield fork(watchRolesSaga);
}
